import { useEffect, useReducer } from "react";
import mapValues from "lodash/mapValues";
import chunk from "lodash/chunk";
import qs from "qs";

import { fetchRangeConfig, fetchRootConfig } from "utils/api";

const queryToState = (query, rootConfig, rangeConfig) => {
  const selectLanguage = query.l || rootConfig.lang;

  const selectedCollection =
    rootConfig.collections.find((collection) => collection.id === query.c) ||
    rootConfig.collections[0];

  const selectedRange =
    rootConfig.ranges.find((range) => range.id === query.r) ||
    rootConfig.ranges[0];

  if (!rangeConfig) {
    return {
      loadRange: selectedRange,
      selectedCollection,
      selectedRange,
      language: selectLanguage
    };
  }

  const selectedView = selectedRange.views[+query.v] || selectedRange.views[0];
  const selectedVariant = rangeConfig[selectedView.variant_id];

  const parsedOptionsByLayerId =
    typeof query.o === "string"
      ? chunk(query.o.split(""), 2).reduce((result, kv) => {
        const layerId = kv[0];
        const optionId = kv[1];
        result[layerId] = optionId;
        return result;
      }, {})
      : {};

  const selectedOptions = selectedVariant.layers.reduce((result, layer) => {
    if (!layer.id) {
      return result;
    }

    const optionId = parsedOptionsByLayerId[layer.id];
    const option =
      layer.options.find((option) => option.id === optionId) ||
      layer.options[0];
    result[layer.id] = option;
    return result;
  }, {});

  // Preserve values ve don't have in layers for current variant,
  // i.e. when we load page on top variant that has no color layer, we still
  // need to keep selected color option
  for (const [layerId, optionId] of Object.entries(parsedOptionsByLayerId)) {
    if (selectedVariant.layers.find((layer) => layer.id === layerId)) {
      continue;
    }
    // Just keep a fake object here for now, when we switch to variant that has
    // this layer, it will be filled accordingly with the real option object
    selectedOptions[layerId] = { id: optionId, missing: true };
  }

  return {
    selectedCollection,
    selectedRange,
    selectedView,
    selectedVariant,
    selectedOptions,
    selectedLayer: null,
    confirmedLayers: [],
    language: selectLanguage
  };
};

const stateToQuery = (state) => {
  if (!state.selectedRange || !state.selectedVariant) {
    return {};
  }

  const query = {
    c: state.selectedCollection.id,
    r: state.selectedRange.id,
    v: state.selectedRange.views.indexOf(state.selectedView),
    l: state.language
  };

  if ("selectedOptions" in state) {
    query.o = Object.entries(state.selectedOptions || {})
      .map(([layerId, option]) => `${layerId}${option.id}`)
      .join("");
  }

  return query;
};

const getInitialState = (rootConfig, rangeConfig) => {
  const query = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  return queryToState(query, rootConfig, rangeConfig);
};

const persistState = (state) => {
  const query = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  const nextQuery = stateToQuery(state);

  window.history.replaceState(
    {},
    null,
    qs.stringify(
      { ...query, ...nextQuery },
      {
        addQueryPrefix: true,
        strictNullHandling: true
      }
    )
  );
};

const processAction = (state, action) => {
  switch (action.type) {
    case "load-root-config": {
      return {
        ...state,
        rootConfig: action.config,
        ...getInitialState(action.config, null)
      };
    }

    case "load-range-config": {
      let nextState = {
        ...state,
        loadRange: null,
        rangeConfig: {
          ...state.rangeConfig,
          ...action.config
        }
      };

      if (!state.loaded) {
        nextState = {
          ...nextState,
          ...getInitialState(state.rootConfig, action.config),
          loaded: true
        };
      }

      return nextState;
    }

    case "select-collection": {
      // Try to find the similar range in the next collection
      const nextRange = state.rootConfig.ranges.find(
        (range) =>
          range.collection_id === action.collection.id &&
          range.name === state.selectedRange.name
      );

      // Change only collection when next range is not found
      if (!nextRange) {
        return {
          ...state,
          selectedCollection: action.collection
        };
      }

      // Run side effect to load next range, it will trigger select-range action when loaded
      return {
        ...state,
        loadRange: nextRange,
        selectedCollection: action.collection
      };
    }

    case "select-range": {
      // Don't reset options when selecting the same range
      if (state.selectedRange === action.range) {
        return state;
      }

      let nextView = action.range.views[0];
      if (!state.rangeConfig[nextView.variant_id]) {
        return {
          ...state,
          // Run side effect to load range, it will re-trigger 'select-range' when done
          loadRange: action.range
        };
      }

      let nextVariant = state.rangeConfig[nextView.variant_id];
      let nextOptions = nextVariant.layers.reduce((result, layer) => {
        if (!layer.id) {
          return result;
        }

        const matchingLayer = state.selectedVariant.layers.find(
          (l) => l.name === layer.name
        );
        const matchingOption =
          matchingLayer &&
          layer.options.find(
            (o) => o.name === state.selectedOptions[matchingLayer.id]?.name
          );

        const exceptLayers = ["Color", "Finishes", "Range hood"];

        if (exceptLayers.includes(layer.name) && matchingOption) {
          result[layer.id] = state.selectedOptions[layer.id];
        } else {
          result[layer.id] = layer.options[0];
        }

        return result;
      }, {});

      // keep view for the same collection
      if (state.selectedRange.collection_id === action.range.collection_id) {
        nextView = action.range.views.filter(view => view.name === state.selectedView.name)[0];
        nextVariant = state.rangeConfig[nextView.variant_id];

        // Preserve options between layer change, if any
        nextOptions = mapValues(
          nextOptions,
          (prevOption, prevLayerId) => {
            const nextLayer = nextVariant.layers.find(
              (layer) => layer.id === prevLayerId
            );
            if (!nextLayer) {
              return prevOption;
            }
            return nextLayer.options.find(
              (option) => option.id === prevOption.id
            );
          }
        );
      }

      return {
        ...state,
        selectedRange: action.range,
        selectedView: nextView,
        selectedVariant: nextVariant,
        selectedOptions: nextOptions,
        confirmedLayers: []
      };
    }

    case "select-view": {
      const nextVariant = state.rangeConfig[action.view.variant_id];

      // Preserve selected layer with the same id when switching (nextLayer may not exist)
      const nextLayer = state.selectedLayer
        ? nextVariant.layers.find((layer) => layer.id === state.selectedLayer.id)
        : null;

      // Preserve options between layer change, if any
      const nextOptions = mapValues(
        state.selectedOptions,
        (prevOption, prevLayerId) => {
          const nextLayer = nextVariant.layers.find(
            (layer) => layer.id === prevLayerId
          );

          if (!nextLayer) {
            return prevOption;
          }

          return nextLayer.options.find(
            (option) => option.id === prevOption.id
          );
        }
      );

      return {
        ...state,
        selectedView: action.view,
        selectedVariant: nextVariant,
        selectedOptions: nextOptions,
        selectedLayer: nextLayer
      };
    }

    case "select-option": {
      const nextOptions = { ...state.selectedOptions };
      nextOptions[action.layer.id] = action.option;
      let nextConfirmedLayers = state.confirmedLayers;
      if (!nextConfirmedLayers.includes(action.layer.id)) {
        nextConfirmedLayers = [...state.confirmedLayers, action.layer.id];
      }
      return {
        ...state,
        selectedOptions: nextOptions,
        confirmedLayers: nextConfirmedLayers
      };
    }

    case "select-layer": {
      let nextLayer = action.layer;
      // If we had previously selected any layer, we add it to confirmed
      let nextConfirmedLayers = state.confirmedLayers;
      if (
        state.selectedLayer &&
        !nextConfirmedLayers.includes(state.selectedLayer.id)
      ) {
        nextConfirmedLayers = [
          ...state.confirmedLayers,
          state.selectedLayer.id
        ];
      }
      return {
        ...state,
        selectedLayer: nextLayer,
        confirmedLayers: nextConfirmedLayers
      };
    }

    case "select-language": {
      const language = action.language;

      return {
        ...state,
        language
      };
    }

    default: {
      console.warn("Unknown action type", action);
      return state;
    }
  }
};

const useAppState = (updateUrl = true) => {
  const [state, dispatch] = useReducer(processAction, {});

  useEffect(() => {
    fetchRootConfig().then(
      (config) => {
        dispatch({ type: "load-root-config", config });
      },
      (error) => {
        alert("Failed to load root config");
      }
    );
  }, []);

  useEffect(() => {
    if (!state.loadRange) {
      return;
    }

    fetchRangeConfig(state.loadRange.variant_config).then(
      (config) => {
        dispatch({ type: "load-range-config", config });
        if (state.loaded) {
          dispatch({ type: "select-range", range: state.loadRange });
        }
      },
      (error) => {
        alert("Failed to load range config");
      }
    );
  }, [state.loadRange, state.loaded]);

  // Save state to location query on each change
  useEffect(() => {
    if (!updateUrl) {
      return;
    }

    persistState(state);
  }, [state, updateUrl]);

  const actions = {
    selectCollection: (v) => dispatch({ type: "select-collection", collection: v }),
    selectRange: (range) => dispatch({ type: "select-range", range }),
    selectLayer: (layer) => dispatch({ type: "select-layer", layer }),
    selectView: (view) => dispatch({ type: "select-view", view }),
    selectOption: (layer, option) => dispatch({ type: "select-option", layer, option }),
    selectLanguage: (language) => dispatch({ type: "select-language", language })
  };

  return [state, actions];
};

export default useAppState;
