import { useState } from "react";
import { useLocales } from "react-localized";

import Link from "components/Link";
import Input from "components/Input";
import Dropdown from "components/Dropdown";
import PendingModal from "components/PendingModal";
import SquareCheckbox from "components/SquareCheckbox";
import FormImageOption from "components/FormImageOption";

import useAppState from "utils/use-app-state";
import { convertRangeState, sendRequest } from "utils/api";

import CloseIcon from "assets/close-icon.svg";
import CuttingBoard from "assets/accessory-cutting-board.png";
import SmoothGriddle from "assets/accessory-smooth-griddle.png";
import RidgedGriddle from "assets/accessory-ridged-griddle.png";
import SimmerPlateCover from "assets/accessory-simmer-plate-cover.png";
import SimmerPlate from "assets/accessory-simmer-plate.png";

import styles from "./RangeQuotePage.module.css";
import WarningIcon from "../assets/warning-empty.svg";
import { custom_url, defineImageUrl } from "../utils/images";
import Button from "../components/Button";
import RangePreview from "../components/RangePreview";
import Footnote from "../components/Footnote";
import cx from "classnames";
import useOptions from "../utils/options";
import { Container, Grid, IconButton } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useNotification } from "../provider/NotificationProvider";

const AccessoryImages = [
  SmoothGriddle,
  SimmerPlate,
  SimmerPlateCover,
  CuttingBoard,
  RidgedGriddle
];

function RangeQuotePage() {
  const { gettext } = useLocales();
  const navigate = useNavigate();
  const location = useLocation();
  const { showNotification } = useNotification();

  const [state] = useAppState(false);
  const [submitStatus, setSubmitStatus] = useState("none");
  const [requestBrochure, setRequestBrochure] = useState(false);
  const [countrySupported, setCountrySupported] = useState(true);
  const [resetKey, setResetKey] = useState("default");
  const [selectedHood, setSelectedHood] = useState();
  const [selectedViewIndex, setViewIndex] = useState(0);
  const [province, setProvince] = useState();
  const [gasType, setGasType] = useState();
  const [height, setHeight] = useState();
  const [brochureProvince, setBrochureProvince] = useState();
  const [accessories, setAccessories] = useState([]);

  const {
    provinceOptions,
    monthOptions,
    heightOptions,
    gasOptions,
    freightOptions,
    accessoryOptions
  } = useOptions();

  const yearOptions = [];
  const currentYear = new Date().getFullYear();

  for (let i = currentYear + 10; i >= currentYear; i--) {
    yearOptions.unshift({
      id: i,
      label: `${i}`
    });
  }

  if (!state.loaded) {
    return null;
  }

  const hoods = (state.selectedVariant?.layers || [])
    .filter((layer) => layer.target === "hood")
    .flatMap((layer) => layer.options)
    .filter(option => !option.hidden);

  const standardHoods = hoods.filter((hood) => hood.name.split(" ").at(-1) === "(standard)");
  const customHoods = hoods.filter((hood) => hood.name.split(" ").at(-1) === "(custom)");

  const submitHandler = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const form = Object.fromEntries(formData.entries());

    let freight = freightOptions.find(option => option.id === "ocean");
    if (form.air_delivery) {
      freight = freightOptions.find(option => option.id === "air");
    }

    const link = `${window.location.protocol}//${window.location.hostname}/${window.location.search}`;

    const data = {
      first_name: form.first_name,
      last_name: form.last_name,
      email: form.email,
      phone: form.phone,
      city: form.city,
      comments: form.comments,
      language: state.language,
      link: link,
      range: convertRangeState(state, gasType, height),
      province: province,
      hood: selectedHood,
      accessories: accessories,
      delivery: {
        date_month: form.date_month,
        date_year: form.date_year,
        freight: {
          id: freight.id,
          name: freight.label
        }
      }
    };

    if (requestBrochure) {
      data.brochure = {
        street: form.brochure_street,
        city: form.brochure_city,
        postal_code: form.brochure_zip,
        province: brochureProvince
      };
    }

    data.side_images = defineImageUrl(state, 0);
    data.front_images = defineImageUrl(state, 1);
    data.top_images = defineImageUrl(state, 2);

    setSubmitStatus("pending");

    sendRequest(data)
      .then((response => {
        setSubmitStatus("done");
      }))
      .catch(error => {
        showNotification(gettext("Failed to send request. Check required fields \"*\""), "error");
        setSubmitStatus("none");
      });
  };

  const navigateTo = (path) => {
    navigate({
      pathname: path,
      search: location.search
    });
  };

  const selectAccessory = (item) => {
    const index = accessories.findIndex(accessory => accessory.id === item.id);

    if (index === -1) {
      accessories.push(item);
    } else {
      accessories.splice(index, 1);
    }
  };

  return (
    <Container maxWidth={false}>
      <Grid container spacing={2} justifyContent={"center"} direction={"column"}>
        <div className={styles.container}>
          {submitStatus !== "none" && (
            <PendingModal pending={submitStatus === "pending"} />
          )}

          <Grid item xs={12}>
            <Grid container spacing={2} justifyContent={"center"}>
              <Grid item xs={4}>
                <IconButton
                  onClick={() => {
                    navigateTo("/");
                  }}>
                  <img src={CloseIcon} alt={gettext("Close")} />
                </IconButton>
              </Grid>

              <Grid item xs={4}>
                <h1 className={styles.title}>
                  {gettext("Get a quote")}
                </h1>
              </Grid>

              <Grid item xs={4}>
                <Link
                  className={styles.resetLink}
                  onClick={() => {
                    setResetKey(`${Math.random()}`);
                  }}
                >
                  {gettext("Reset all fields")}
                </Link>
              </Grid>
            </Grid>
          </Grid>

          <form
            key={resetKey}
            className={styles.form}
            onSubmit={submitHandler}
          >
            <div className={styles.fieldsColumn}>
              <div className={styles.fieldGroup}>
                <Input label={gettext("First Name")} required name="first_name" />
                <Input label={gettext("Last Name")} required name="last_name" />
              </div>
              <div className={styles.fieldGroup}>
                <Input
                  label={gettext("Email")}
                  required
                  type="email"
                  name="email"
                />
                <Input label={gettext("Phone Number")} required name="phone" />
              </div>
              <div className={styles.fieldGroup}>
                <Input label={gettext("City")} required name="city" />
                <Dropdown
                  label={gettext("Province/Territory")}
                  options={provinceOptions}
                  name="province"
                  required
                  onChange={({ selectedItem }) => {
                    setCountrySupported(selectedItem?.id !== "us");
                    setProvince({
                      id: selectedItem?.id,
                      name: selectedItem?.label
                    });
                  }}
                />
              </div>

              {!countrySupported && (
                <div className={styles.formHint}>
                  {gettext(
                    "The French Barn is the exclusive distributor for Lacanche in Canada. For other countries, please feel free to print / save your current configuration and contact your local distributor."
                  )}
                </div>
              )}

              <Input
                label={gettext("Comments/Questions")}
                type="textarea"
                name="comments"
              />

              <div className={styles.formSpacer} />

              <SquareCheckbox
                className={styles.brochureCheckbox}
                label={gettext("I would like to receive a Brochure package")}
                name="request_brochure"
                value="true"
                onChange={(event) => {
                  setRequestBrochure(event.target.checked);
                }}
              />
              {requestBrochure && (
                <>
                  <div className={styles.fieldGroup}>
                    <Input label={gettext("Street")} required name="brochure_street" />
                    <Input label={gettext("City")} required name="brochure_city" />
                  </div>
                  <div className={styles.fieldGroup}>
                    <Dropdown
                      label={gettext("Province/Territory")}
                      options={provinceOptions.filter((o) => o.id !== "us")}
                      name="brochure_province"
                      required
                      onChange={({ selectedItem }) => {
                        setBrochureProvince({
                          id: selectedItem?.id,
                          name: selectedItem?.label
                        });
                      }}
                    />
                    <Input label={gettext("Postal Code")} required name="brochure_zip" />
                  </div>

                  <div className={styles.formSpacer} />
                </>
              )}
              {!requestBrochure && <div className={styles.formSpacer} />}

              <div className={styles.fieldGroup}>
                <Dropdown
                  label={gettext("Gas Type")}
                  options={gasOptions}
                  name="gas_type"
                  value={gasType?.id}
                  required
                  onChange={({ selectedItem }) => {
                    setGasType({
                      id: selectedItem?.id,
                      name: selectedItem?.label
                    });
                  }}
                />
                <Dropdown
                  label={gettext("Range Height")}
                  options={heightOptions}
                  name="height"
                  value={height?.id}
                  required
                  onChange={({ selectedItem }) => {
                    setHeight({
                      id: selectedItem?.id,
                      name: selectedItem?.label
                    });
                  }}
                />
              </div>

              <div className={styles.formSpacer} />

              <div className={styles.categoryLabel}>
                {gettext("Anticipated delivery")}
              </div>
              <div className={styles.fieldGroup}>
                <Dropdown
                  label={gettext("Month")}
                  options={monthOptions}
                  name="date_month"
                  value="January"
                />
                <Dropdown
                  label={gettext("Year")}
                  options={yearOptions}
                  name="date_year"
                  value={yearOptions[0].label}
                />
              </div>
              <div className={styles.formHint}>
                {gettext(
                  "Air Freight: 7-8 months (Add’I charges apply), Ocean Freight: 9-10 months"
                )}{" "}
              </div>
              <SquareCheckbox
                label={gettext(
                  "I'm interested in Air Freight for quicker delivery"
                )}
                name="air_delivery"
                value="true"
              />

              <div className={styles.formSpacer} />

              <div className={styles.categoryLabel}>{gettext("Hood")}</div>
              <div className={styles.formHint}>
                {gettext("To request a quote, please choose one of the following options")}
              </div>
              <div className={styles.formWarningHint}>
                <img className={styles.warningIcon} src={WarningIcon} alt="" />
                {gettext("Please note that the hood color resembles the color of the chosen range by default. If other is required, please mention it in the comment section above.")}
              </div>

              <div className={styles.categoryLabel}>{gettext("Standard")}:</div>
              <div className={styles.checkboxGroup}>
                {standardHoods.map((option, index) => {
                  const hoodName = `hood_${option.name.replace(" (standard)", "")
                    .toLowerCase()
                    .replace(/[ ]/g, "_")}`;

                  return (
                    <FormImageOption
                      key={option.id}
                      label={gettext(option.name).replace(" (standard)", "")}
                      imageUrl={custom_url(option.image, "Black_(Matte)", "${url_safe(options.A.name)}")}
                      isSelected={selectedHood?.id === hoodName}
                      name={hoodName}
                      onClick={(hood) => setSelectedHood(hood)}
                    />
                  );
                })
                }
              </div>

              <div className={styles.categoryLabel}>{gettext("Custom (add’l charges apply)")}:</div>
              <div className={styles.checkboxGroup}>
                {customHoods.map((option, index) => {
                    const hoodName = `hood_${option.name.replace(" (custom)", "")
                      .toLowerCase()
                      .replace(/[ ]/g, "_")}`;

                    return (
                      <FormImageOption
                        key={option.id}
                        label={gettext(option.name).replace(" (custom)", "")}
                        imageUrl={custom_url(option.image, "Black_(Matte)", "${url_safe(options.A.name)}")}
                        isSelected={selectedHood?.id === hoodName}
                        name={hoodName}
                        onClick={(hood) => setSelectedHood(hood)}
                      />
                    );
                  }
                )
                }
              </div>

              <div className={styles.formSpacer} />

              <div className={styles.categoryLabel}>{gettext("Accessories")}</div>
              <div className={styles.checkboxGroup}>
                {
                  accessoryOptions.map((accessory, index) => {
                      return (
                        <FormImageOption
                          key={accessory.id}
                          label={gettext(accessory.label)}
                          imageUrl={AccessoryImages[index]}
                          name={accessory.id}
                          onClick={(accessory) => selectAccessory(accessory)}
                        />
                      );
                    }
                  )}
              </div>
              <div className={styles.formActions}>
                <Button
                  className={styles.actionButton}
                  filled
                  type="submit"
                  disabled={!countrySupported}
                >
                  {gettext("Send your request")}
                </Button>
              </div>
            </div>
            <div className={styles.viewerColumn}>
              <div className={styles.rangeBox}>
                <RangePreview
                  rootConfig={state.rootConfig}
                  rangeConfig={state.rangeConfig}
                  range={state.selectedRange}
                  options={state.selectedOptions}
                  view={state.selectedRange.views[selectedViewIndex]}
                />
              </div>
              <div className={styles.viewToggle}>
                {state.selectedRange.views.map((view, index) => (
                  <button
                    key={index}
                    className={cx(
                      styles.viewButton,
                      selectedViewIndex === index && styles.viewButtonSelected
                    )}
                    type="button"
                    onClick={() => setViewIndex(index)}
                  >
                    <img
                      className={styles.viewIcon}
                      src={view.preview_icon}
                      alt={view.name}
                    />
                  </button>
                ))}
              </div>
              <div className={styles.viewFootnote}>
                <Footnote />
              </div>
            </div>
          </form>
        </div>
      </Grid>
    </Container>
  );
}

export default RangeQuotePage;
