import * as React from 'react';
import { useState } from 'react';
import { AppBar, Container, Grid, IconButton, Menu, MenuItem, Toolbar, Tooltip, Typography } from '@mui/material';
import { AccountCircle } from '@mui/icons-material';
import Logo from '../assets/logo.png';
import styles from './Bar.module.css';
import { useLocales } from 'react-localized';
import { AuthenticationService } from '../services/AuthenticationService';

const settings = [{
  name: 'Logout',
  path: '/logout'
}];

function Bar() {
  const { gettext } = useLocales();
  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = (path) => {
    setAnchorElUser(null);
    AuthenticationService.logout();
  };

  return (
    <AppBar position="static">
      <Container maxWidth="false">
        <Toolbar disableGutters>
          <Grid container spacing={2} justifyContent={'space-between'} alignItems={'center'}>
            <Grid item>
              <img
                className={styles.logo}
                alt={gettext('Lacanche logo')}
                src={Logo} />
            </Grid>

            <Grid item>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <AccountCircle />
                </IconButton>
              </Tooltip>

              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem key={setting.name} onClick={() => handleCloseUserMenu(setting.path)}>
                    <Typography textAlign="center">{setting.name}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Grid>
          </Grid>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Bar;