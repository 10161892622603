import settings from '../settings.json';
import { AuthenticationService } from '../services/AuthenticationService';


const request = async (url, method, body, parseResponse) => {
  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  };

  const token = AuthenticationService.getAccessToken();

  if (token) {
    headers['authorization'] = 'Bearer ' + token;
  }

  const response = await fetch(url, {
    method: method,
    body: body ? JSON.stringify(body) : undefined,
    headers: headers
  });

  if (!response.ok) {
    const result = await response.json();
    throw new Error(result.message);
  }

  if (parseResponse) {
    return await response.json();
  }

  return response;
};

export const signIn = (body) =>
  request(`${settings.base_path}/login`, 'POST', body, true);

export const signOut = (body) =>
  request(`${settings.base_path}/logout`, 'POST', body, true);

export const fetchSummary = (body) =>
  request(`${settings.base_path}/summary`, 'POST', body, true);

export const sendRequest = (body) =>
  request(`${settings.base_path}/requests`, 'POST', body, true);

export const fetchRequests = (page, size, orderBy, order, search) =>
  request(`${settings.base_path}/requests?page=${page}&size=${size}&sortBy=${orderBy}&sort=${order}&search=${search}`, 'GET', null, true);

export const fetchRequestById = (id) =>
  request(`${settings.base_path}/requests/${id}`, 'GET', null, true);

export const updateRequest = (body) =>
  request(`${settings.base_path}/requests/${body.id}`, 'PUT', body, true);

export const fetchUsers = () =>
  request(`${settings.base_path}/users`, 'GET', null, true);

export const addUser = (body) =>
  request(`${settings.base_path}/users`, 'POST', body, true);

export const fetchUserById = (id) =>
  request(`${settings.base_path}/users/${id}`, 'GET', null, true);

export const updateUser = (body) =>
  request(`${settings.base_path}/users/${body.id}`, 'PUT', body, true);

export const fetchRootConfig = () =>
  request('/config.json', 'GET', null, true);

export const fetchRangeConfig = (url) =>
  request(url, 'GET', null, true);

export const convertRangeState = (state, gasType, height) => ({
  id: state.selectedRange.id,
  name: state.selectedRange.name,
  collection: state.selectedCollection.name,
  width: state.selectedRange.category,
  height: height,
  gas_type: gasType,
  ...Object.fromEntries(
    Object.entries(state.selectedOptions)
      .filter(([layerId, option]) => {
        const layer = state.selectedVariant.layers.find((l) => l.id === layerId);
        const key = (layer?.name.toLowerCase() || layerId.toLowerCase()).replace(/ /g, '_');
        return key !== 'range_hood';
      })
      .map(([layerId, option]) => {
        const layer = state.selectedVariant.layers.find((l) => l.id === layerId);
        const key = (layer?.name.toLowerCase() || layerId.toLowerCase()).replace(/ /g, '_');
        return [key, {
          id: option.id,
          name: option.name
        }];
      })
  )
});
