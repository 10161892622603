import { LocalizedProvider } from "react-localized";

import en from "locales/en";
import fr from "locales/fr";
import RoutePath from "./routes/RoutePath";
import useAppState from "./utils/use-app-state";

const Locales = { en, fr };

function App() {
  const [state, actions] = useAppState();

  if (!state.language) {
    return null;
  }

  return (
    <LocalizedProvider locales={Locales} selected={state.language}>
      {({ localeReady }) => {
        if (!localeReady) {
          return null;
        }

        return (<RoutePath store={{ state, actions }} />);
      }}
    </LocalizedProvider>
  );
}

export default App;
