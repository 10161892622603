import { cloneElement, useState, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import {
  offset,
  flip,
  shift,
  arrow,
  autoUpdate,
  useFloating,
  useInteractions,
  useHover,
  useFocus,
  useRole,
  useDismiss,
} from '@floating-ui/react-dom-interactions';
import cx from 'classnames';

import styles from './Tooltip.module.css';

function Tooltip({ children, label, size, placement = 'top' }) {
  const [open, setOpen] = useState(false);

  const arrowRef = useRef();

  const { x, y, reference, floating, strategy, context, middlewareData } =
    useFloating({
      placement,
      open,
      onOpenChange: setOpen,
      whileElementsMounted: autoUpdate,
      middleware: [
        offset(10),
        flip(),
        shift({ padding: 8 }),
        arrow({ element: arrowRef }),
      ],
    });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useHover(context, { restMs: 40 }),
    useFocus(context),
    useRole(context, { role: 'tooltip' }),
    useDismiss(context),
  ]);

  if (!label) {
    return children;
  }

  return (
    <>
      {cloneElement(
        children,
        getReferenceProps({ ref: reference, ...children.props }),
      )}
      <AnimatePresence>
        {open && (
          <motion.div
            initial={{ opacity: 0, scale: 0.85 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0 }}
            transition={{ type: 'spring', damping: 20, stiffness: 300 }}
            {...getFloatingProps({
              ref: floating,
              className: cx(styles.tooltip, size === 'large' && styles.large),
              style: {
                position: strategy,
                top: y ?? '',
                left: x ?? '',
              },
            })}
          >
            {label}
            <div
              ref={arrowRef}
              className={styles.arrow}
              style={{
                left: middlewareData.arrow?.x || 0,
              }}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}

export default Tooltip;
