import cx from 'classnames';

import styles from './Link.module.css';

/* eslint-disable jsx-a11y/anchor-is-valid */

function Link({ children, href, selected, onClick, ...otherProps }) {
  return (
    <a
      className={cx(
        styles.link,
        href && styles.navigational,
        selected && styles.selected,
      )}
      href={href || '#'}
      {...otherProps}
      onClick={(event) => {
        event.preventDefault();
        onClick?.(event);
      }}
    >
      {children}
    </a>
  );
}

export default Link;
