import { Snackbar } from '@mui/material';
import React, { createContext, forwardRef, useContext, useState } from 'react';
import MuiAlert from '@mui/material/Alert';

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export function NotificationProvider({ children }) {

  const [notification, setNotification] = useState({
      open: false,
      message: '',
      severity: ''
    }
  );

  const showNotification = (message, type) => {
    setNotification({
      open: true,
      message: message,
      severity: type
    });
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setNotification({
      ...notification,
      open: false
    });
  };

  return (
    <NotificationContext.Provider value={{ showNotification, setNotification }}>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={notification.open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={notification.severity}
          sx={{ width: '100%', minWidth: '250px' }}
        >
          {notification.message}
        </Alert>
      </Snackbar>

      {children}
    </NotificationContext.Provider>
  );
}

const NotificationContext = createContext(null);
export const useNotification = () => useContext(NotificationContext);