import { useLayoutEffect, useRef, useState } from 'react';
import useResizeObserver from '@react-hook/resize-observer';
import cx from 'classnames';

import { preload } from 'utils/images';

import styles from './LayeredCanvas.module.css';

const Margin = 32;

const getScaleFactor = (containerSize, imageSize) => {
  const containerRatio = containerSize[0] / containerSize[1];
  const imageRatio = imageSize[0] / imageSize[1];
  const limitedVertically = containerRatio > imageRatio;

  return limitedVertically
    ? containerSize[1] / imageSize[1]
    : containerSize[0] / imageSize[0];
};

const useSize = (target) => {
  const [size, setSize] = useState();

  useLayoutEffect(() => {
    setSize(target.current.getBoundingClientRect());
  }, [target]);

  useResizeObserver(target, (entry) => setSize(entry.contentRect));
  return size;
};

function Layer({ url, ...props }) {
  const [displayedUrl, setDisplayedUrl] = useState(url);

  useLayoutEffect(() => {
    if (displayedUrl === url) {
      return;
    }

    preload(url).then(
      () => setDisplayedUrl(url),
      (error) => setDisplayedUrl(null)
    );
  }, [displayedUrl, url]);

  return (
    <div
      style={{
        backgroundImage: displayedUrl ? `url("${displayedUrl}")` : undefined
      }}
      {...props}
    />
  );
}

function Marker({
                  coords,
                  label,
                  selected,
                  warning,
                  confirmed,
                  scaleFactor,
                  onClick
                }) {
  const [p1, p2] = coords;
  const align = p1[0] < p2[0] ? 'left' : 'right';

  const labelElement = useRef();
  const [labelWidth, setLabelWidth] = useState(0);

  useLayoutEffect(() => {
    setLabelWidth(labelElement.current.getComputedTextLength());
  }, [label, scaleFactor]);

  return (
    <g
      className={cx([styles.marker, selected && styles.markerSelected])}
      onClick={onClick}
    >
      <text
        ref={labelElement}
        className={styles.markerLabel}
        style={{ fontSize: 14 / scaleFactor }}
        textAnchor={align === 'left' ? 'start' : 'end'}
        x={p1[0]}
        y={p1[1] - 20}
      >
        {label}
      </text>
      <line
        className={styles.markerLine}
        style={{
          strokeDasharray: `${1.5 / scaleFactor} ${5 / scaleFactor}`,
          strokeWidth: 1.5 / scaleFactor
        }}
        x1={p1[0]}
        y1={p1[1]}
        x2={p2[0]}
        y2={p1[1]}
      />
      <line
        className={styles.markerLine}
        style={{
          strokeDasharray: `${1.5 / scaleFactor} ${5 / scaleFactor}`,
          strokeWidth: 1.5 / scaleFactor
        }}
        x1={p2[0]}
        y1={p1[1]}
        x2={p2[0]}
        y2={p2[1]}
      />
      <circle
        className={styles.markerPoint}
        style={{
          strokeWidth: 2 / scaleFactor
        }}
        r={5 / scaleFactor}
        cx={p2[0]}
        cy={p2[1]}
      />
      {warning && (
        <g
          transform={`translate(${
            p1[0] +
            (labelWidth + 10) * (align === 'left' ? 1 : -1) +
            (align === 'right' ? -34 : 0)
          }, ${p1[1] - 20 - 14 / scaleFactor / 2 - 8}) scale(2)`}
        >
          <path
            d="M7.99992 14.6673C11.6818 14.6673 14.6666 11.6825 14.6666 8.00065C14.6666 4.31875 11.6818 1.33398 7.99992 1.33398C4.31802 1.33398 1.33325 4.31875 1.33325 8.00065C1.33325 11.6825 4.31802 14.6673 7.99992 14.6673Z"
            fill="white"
            stroke="#8B0000"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8.00659 8.33203L8.00659 4.9987"
            stroke="#8B0000"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8.00659 10.9926L7.99992 11"
            stroke="#8B0000"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      )}
    </g>
  );
}

function LayeredCanvas({
                         pixelMargin = 0,
                         className,
                         imageSize = [2560, 1440],
                         layers,
                         markers = [],
                         onMarkerClick
                       }) {
  const container = useRef();
  const containerSize = useSize(container);

  const textScaleFactor = containerSize
    ? getScaleFactor([containerSize.width, containerSize.height], imageSize)
    : 1;

  const scaleFactor = containerSize ? containerSize.width / imageSize[0] : 1;
  const viewportMargin = pixelMargin * scaleFactor - Margin;

  return (
    <div
      ref={container}
      className={cx(className, styles.container)}
      style={{
        marginLeft: -viewportMargin,
        marginRight: -viewportMargin
      }}
    >
      {layers.map((layer, index) => (
        <Layer key={index} className={styles.image} url={layer.url} />
      ))}

      <svg
        className={styles.markers}
        viewBox={`0 0 ${imageSize[0]} ${imageSize[1]}`}
        preserveAspectRatio="xMidYMid meet"
        vectorEffect="non-scaling-stroke"
      >
        {markers.map((marker, index) => (
          <Marker
            key={index}
            label={marker.label}
            coords={marker.coords}
            selected={marker.selected}
            confirmed={marker.confirmed}
            warning={marker.warning}
            scaleFactor={textScaleFactor}
            onClick={() => {
              onMarkerClick(marker, index);
            }}
          />
        ))}
      </svg>
    </div>
  );
}

export default LayeredCanvas;
