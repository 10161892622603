import { useLayoutEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';

function RenderAboveEverything({ className, children }) {
  const el = useMemo(() => document.createElement('div'), []);

  if (className) {
    el.className = className;
  }

  useLayoutEffect(() => {
    document.body.appendChild(el);
    return () => document.body.removeChild(el);
  }, [el]);

  return createPortal(children, el);
}

export default RenderAboveEverything;
