import { evaluate } from './templates';
import mapValues from 'lodash/mapValues';

export const preload = (url) =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve();
    img.onerror = () => reject();
    img.src = url;
  });

export const custom_url = (str, to, pattern) => str.replace(pattern, to);

export const defineImageUrl = (state, selectedViewIndex) => {
  const range = state.selectedRange;
  const view = range.views[selectedViewIndex];
  const rangeConfig = state.rangeConfig;
  const options = state.selectedOptions;
  const variant = rangeConfig[view.variant_id];
  const variantSpecificOptions = mapValues(options, (prevOption, layerId) => {
    const layer = variant.layers.find((layer) => layer.id === layerId);

    if (!layer) {
      return { id: prevOption.id, missing: true };
    }

    return layer.options.find((option) => option.id === prevOption.id);
  });

  return variant.layers
    .filter((layer) => {
      if (layer.target) {
        return false;
      }
      if (!layer.render_condition) {
        return true;
      }

      return (
        evaluate(layer.render_condition, {
          range,
          view,
          variant,
          layer,
          option: variantSpecificOptions[layer.id],
          options: variantSpecificOptions
        }) === 'true'
      );
    })
    .map((layer) => {
        return (
          evaluate(
            layer.image_template || variantSpecificOptions[layer.id]?.image,
            {
              range,
              view,
              variant,
              layer,
              option: variantSpecificOptions[layer.id],
              options: variantSpecificOptions
            }
          )
        );
      }
    ).filter((url) => !(url === 'undefined' || url === '...'));
};
