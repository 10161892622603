import * as React from 'react';
import { useState } from 'react';
import { useLocales } from 'react-localized';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Grid,
  TextField,
  Typography
} from '@mui/material';
import styles from '../page/SignInPage.module.css';
import Logo from '../assets/logo.png';
import { AuthenticationService } from '../services/AuthenticationService';

function LayoutPage() {
  const { gettext } = useLocales();

  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState({
    value: '',
    error: false
  });
  const [password, setPassword] = useState({
    value: '',
    error: false
  });
  const [loginError, setLoginError] = useState('');

  const handleSubmit = () => {
    if (email.value === '') {
      setEmail({
        ...email,
        error: true
      });
    }

    if (password.value === '') {
      setPassword({
        ...password,
        error: true
      });
    } else {
      setLoginError('');
      setIsLoading(true);

      AuthenticationService.login({
        email: email.value,
        password: password.value
      })
        .catch(error => {
            setLoginError(error.message);
            setIsLoading(false);
          }
        );
    }
  };

  return (
    <Container fixed>
      <Box sx={{ height: '100vh' }}>
        <Grid container spacing={2} justifyContent={'center'} alignItems={'center'} height={'100%'}>
          <Grid item xs={4}>
            <Card raised>
              <CardContent>
                <Grid container spacing={2} direction={'column'}>
                  <Grid item alignSelf={'center'}>
                    <img
                      className={styles.logo}
                      alt={gettext('Lacanche logo')}
                      src={Logo} />
                  </Grid>

                  <Grid item container direction={'row'}>
                    <TextField
                      fullWidth
                      label={gettext('Email')}
                      variant={'outlined'}
                      value={email.value}
                      error={email.error}
                      helperText={email.error && 'Required field'}
                      onChange={(event) => setEmail({
                        value: event.target.value,
                        error: false
                      })}
                    />
                  </Grid>

                  <Grid item>
                    <TextField
                      fullWidth
                      label={gettext('Password')}
                      variant={'outlined'}
                      value={password.value}
                      error={password.error}
                      type={'password'}
                      helperText={password.error && 'Required field'}
                      onChange={(event) => setPassword({
                        value: event.target.value,
                        error: false
                      })}
                    />
                  </Grid>

                  <Grid item>
                    <Button
                      fullWidth
                      variant="contained"
                      onClick={() => handleSubmit()}
                    >
                      {isLoading
                        ? (<CircularProgress color={'warning'} />)
                        : gettext('Sign in')}
                    </Button>

                    {loginError && (
                      <Typography
                        className={styles.error}
                        align={'center'}
                        variant={'subtitle1'}
                      >
                        {loginError}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default LayoutPage;
