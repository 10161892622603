import cx from 'classnames';
import { useLocales } from 'react-localized';

import Tooltip from './Tooltip';

import WarningIcon from 'assets/warning.svg';
import styles from './ElementButton.module.css';

// const SP = '\u00A0'; // Non-breakable space symbol

function ElementButton({
                         className,
                         narrow,
                         wide,
                         disabled,
                         selected,
                         label,
                         children,
                         onClick,
                         ...otherProps
                       }) {
  const { gettext } = useLocales();

  return (
    <div
      className={cx(
        className,
        styles.container,
        disabled && styles.disabled,
        selected && styles.selected,
        narrow && styles.narrow,
        wide && styles.wide
      )}
      role="button"
      onClick={(event) => {
        if (disabled) {
          return;
        }
        onClick?.(event);
      }}
      {...otherProps}
    >
      <div className={styles.content}>{children}</div>
      {disabled && (
        <Tooltip
          label={gettext(`Not available in current configuration`)}
          size="large"
        >
          <img className={styles.disabledIcon} src={WarningIcon} alt="" />
        </Tooltip>
      )}
      <div className={styles.label}>{label}</div>
    </div>
  );
}

export default ElementButton;
