import ElementButton from './ElementButton';
import styles from './ColorElementButton.module.css';

function ColorElementButton({ color, ...otherProps }) {
  return (
    <ElementButton className={styles.container} {...otherProps}>
      <div className={styles.colorBox} style={{ backgroundColor: color }} />
    </ElementButton>
  );
}

export default ColorElementButton;
