/* eslint-disable no-new-func */

const compileCache = {};

const functions = {
  lowercase: (str) => str.toLowerCase(),
  url_safe: (str) => str.replace(/ /g, '_'),
};

export const compile = (str) => {
  const src = `with (sandbox) { return \`${str}\`; }`;
  return new Function('sandbox', src);
};

export const evaluate = (str, context) => {
  if (!compileCache[str]) {
    compileCache[str] = compile(str);
  }

  const fn = compileCache[str];
  return fn({
    ...context,
    ...functions,
  });
};
