import { useEffect, useState } from 'react';
import { useLocales } from 'react-localized';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useParams } from 'react-router-dom';
import { fetchRequestById, updateRequest } from '../utils/api';
import useOptions from 'utils/options';
import PriceOption from '../components/PriceOption';
import Range from '../components/Range';
import { useNotification } from '../provider/NotificationProvider';


function RequestDetailsPage() {
  const { gettext } = useLocales();
  const { showNotification } = useNotification();
  const yearOptions = [];
  const currentYear = new Date().getFullYear();

  for (let i = currentYear + 10; i >= currentYear - 10; i--) {
    yearOptions.unshift({
      id: `${i}`,
      label: `${i}`
    });
  }

  const {
    provinceOptions,
    monthOptions,
    deliveryOptions,
    installationOptions,
    rebateOptions,
    freightOptions,
    hoodOptions
  } = useOptions();

  const [isLoading, setIsLoading] = useState(true);

  const [request, setRequest] = useState({
    installation: null
  });
  const [personal, setPersonal] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    city: '',
    province: null,
    comments: ''
  });
  const [brochure, setBrochure] = useState(null);
  const [delivery, setDelivery] = useState({
    date_month: '',
    date_year: '',
    wg_delivery: null,
    freight: null
  });
  const [hood, setHood] = useState(null);
  const [range, setRange] = useState({
    id: '',
    name: '',
    collection: '',
    width: '',
    height: '',
    gas_type: '',
    base_price: 0,
    color: null,
    finishes: null,
    top: null,
    ovens: null,
    left_option_2: null,
    left_option: null,
    right_option: null,
    right_option_2: null,
    left_cupboard: null,
    right_cupboard: null,
    total_btu: 0,
    total_amps: 0
  });
  const [accessories, setAccessories] = useState([]);

  const [totalRangeCost, setTotalRangeCost] = useState(0);
  const [rebate, setRebate] = useState(null);
  const [tax, setTax] = useState(null);

  const [totalEstimatedCost, setTotalEstimatedCost] = useState(0);
  const [totalCostWithTax, setTotalCostWithTax] = useState(0);
  const [deposit, setDeposit] = useState(0);
  const [payment, setPayment] = useState(0);

  const { id } = useParams();

  useEffect(() => {
    setIsLoading(true);
    fetchRequestById(id)
      .then(response => {
        setRequest(response);
        setPersonal(fillPersonal(response));
        setBrochure(response.brochure);
        setDelivery(fillDelivery(response.delivery));
        setRange(fillRange(response.range));
        setHood(response.hood);
        setAccessories(response.accessories);
        setTax(response.tax);
        setRebate(response.rebate);
        setIsLoading(false);
        setTotalEstimatedCost(response.total_estimated_cost);
        setTotalCostWithTax(response.total_estimated_cost_with_tax);
        setDeposit(response.deposit_value);
        setPayment(response.total_payment_cost);
      })
      .catch(error => {
          setIsLoading(false);
          showNotification('Failed to fetch request', 'error');
        }
      );
  }, []);

  useEffect(() => {
    calcRangeCost();
  }, [range, accessories, hood]);

  useEffect(() => {
    calcRebatePrice();
  }, [rebate?.name, totalRangeCost]);

  useEffect(() => {
    calcTotalEstimatedCost();
  }, [rebate?.price, request.installation, delivery, totalRangeCost]);

  useEffect(() => {
    calcTaxPrice();
  }, [tax?.name, totalEstimatedCost]);

  useEffect(() => {
    calcTotalCostWithTax();
  }, [tax?.price, totalEstimatedCost]);

  useEffect(() => {
    calcTotalCost();
  }, [request, totalCostWithTax]);

  const handleSubmit = () => {
    setIsLoading(true);

    const _accessories = accessories.filter(accessory => accessory.name !== '');
    const data = {
      ...request,
      ...personal,
      brochure,
      range,
      delivery,
      hood,
      accessories: _accessories,
      tax,
      rebate,
      total_estimated_cost: totalEstimatedCost,
      total_estimated_cost_with_tax: totalCostWithTax,
      deposit_value: deposit,
      total_payment_cost: payment
    };

    updateRequest(data)
      .then(response => {
        setIsLoading(false);
        showNotification('Success', 'success');

      })
      .catch(error => {
          setIsLoading(false);
          showNotification('Failed to update request', 'error');
        }
      );
  };

  const fillPersonal = (request) => {
    return {
      first_name: request.first_name,
      last_name: request.last_name,
      email: request.email,
      phone: request.phone,
      city: request.city,
      province: request.province,
      comments: request.comments
    };
  };

  const fillDelivery = (delivery) => {
    return {
      date_month: delivery.date_month,
      date_year: delivery.date_year,
      freight: delivery.freight,
      wg_delivery: delivery.wg_delivery
    };
  };

  const fillRange = (range) => {
    return {
      id: range.id,
      name: range.name,
      collection: range.collection,
      width: range.width,
      height: range.height,
      gas_type: range.gas_type,
      base_price: range.base_price,
      color: range.color,
      finishes: range.finishes,
      top: range.top,
      ovens: range.ovens,
      left_option_2: range.left_option_2,
      left_option: range.left_option,
      right_option: range.right_option,
      right_option_2: range.right_option_2,
      left_cupboard: range.left_cupboard,
      right_cupboard: range.right_cupboard,
      total_btu: range.total_btu,
      total_amps: range.total_amps
    };
  };

  const handlePersonalChange = (event) => {
    const { name, value } = event.target;

    setPersonal({
      ...personal,
      [name]: value
    });
  };

  const handleBrochureChange = (e, obj) => {
    const { name, value } = e.target;
    const _obj = obj ?? {};
    _obj[name] = value;

    setBrochure({
      ...brochure
    });
  };

  const handleDeliveryChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setDelivery({
      ...delivery,
      [name]: value
    });
  };

  const handleRangeChange = (range) => {
    setRange(range);
  };

  const handleTaxChange = (tax) => {
    setTax({ ...tax });
  };

  const calcRangeCost = () => {
    const rangeCost =
      parseFloat(range.base_price) +
      parseFloat(range.color?.price || 0) +
      parseFloat(range.finishes?.price || 0) +
      parseFloat(range.left_option_2?.price || 0) +
      parseFloat(range.left_option?.price || 0) +
      parseFloat(range.right_option?.price || 0) +
      parseFloat(range.right_option_2?.price || 0) +
      parseFloat(range.left_cupboard?.price || 0) +
      parseFloat(range.right_cupboard?.price || 0) || 0;

    const accessoriesCost = accessories
      .map(accessory => accessory.price)
      .reduce((result, currentValue) => {
        return result + parseFloat(currentValue || 0);
      }, 0);

    const hoodCost = parseFloat(hood?.price || 0) + parseFloat(hood?.color?.price || 0);
    const totalCost =
      parseFloat(rangeCost.toFixed(2)) +
      parseFloat(accessoriesCost.toFixed(2)) +
      parseFloat(hoodCost.toFixed(2));
    setTotalRangeCost(totalCost);
  };

  const calcTotalEstimatedCost = () => {
    const totalEstimatedCost =
      totalRangeCost -
      parseFloat(rebate?.price || 0) +
      parseFloat(delivery?.freight?.price || 0) +
      parseFloat(delivery?.wg_delivery?.price || 0) +
      parseFloat(request.installation?.price || 0);

    setTotalEstimatedCost(parseFloat(totalEstimatedCost.toFixed(2)));
  };

  const calcRebatePrice = () => {
    if (rebate) {
      const price = rebate.id === 'manual' ? parseFloat(rebate.price) : totalRangeCost * (5 / 100);
      setRebate({
        ...rebate,
        price: parseFloat(price.toFixed(2))
      });
    }
  };

  const calcTaxPrice = () => {
    if (tax) {
      const price = parseFloat((totalEstimatedCost * (parseFloat(tax?.name || 0) / 100)).toFixed(2));
      setTax({
        ...tax,
        price: parseFloat(price.toFixed(2))
      });
    }
  };
  const calcTotalCostWithTax = () => {
    const totalCostWithTax = totalEstimatedCost + (tax?.price || 0);
    setTotalCostWithTax(parseFloat(totalCostWithTax.toFixed(2)));
  };

  const calcTotalCost = () => {
    const deposit = totalCostWithTax * 0.4;
    const payment = totalCostWithTax - deposit;

    setDeposit(parseFloat(deposit.toFixed(2)));
    setPayment(parseFloat(payment.toFixed(2)));
  };

  return (
    <Grid container spacing={2} justifyContent={'center'}>
      {isLoading && (
        <Box sx={{ height: '100vh', display: 'flex', alignItems: 'center' }}>
          <CircularProgress />
        </Box>
      )}

      {!isLoading && (
        <>
          <Grid item xs={12}>
            <Typography
              align={'center'}
              variant={'h4'}
            >
              {gettext('Request details')}
            </Typography>
          </Grid>

          <Grid item xs={10}>
            <Card raised>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography gutterBottom variant={'h6'}>
                      {gettext('Personal')}
                    </Typography>

                    <Divider />
                  </Grid>

                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      label={gettext('First Name')}
                      variant={'outlined'}
                      value={personal.first_name}
                      name={'first_name'}
                      onChange={(e) => handlePersonalChange(e, personal)}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      label={gettext('Last Name')}
                      variant={'outlined'}
                      value={personal.last_name}
                      name={'last_name'}
                      onChange={(e) => handlePersonalChange(e, personal)}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      label={gettext('Email')}
                      variant={'outlined'}
                      value={personal.email}
                      name={'email'}
                      onChange={(e) => handlePersonalChange(e, personal)}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      label={gettext('Phone Number')}
                      variant={'outlined'}
                      value={personal.phone}
                      name={'phone'}
                      onChange={(e) => handlePersonalChange(e, personal)}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      label={gettext('City')}
                      variant={'outlined'}
                      value={personal.city}
                      name={'city'}
                      onChange={(e) => handlePersonalChange(e, personal)}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <FormControl
                      variant={'outlined'}
                      fullWidth
                    >
                      <InputLabel>
                        {gettext('Province/Territory')}
                      </InputLabel>
                      <Select
                        label={gettext('Province/Territory')}
                        value={personal.province?.id ?? ''}
                        onChange={(e) => {
                          setPersonal({
                            ...personal,
                            province: {
                              ...personal.province,
                              id: e.target.value,
                              name: provinceOptions.find(option => option.id === e.target.value)?.label
                            }
                          });
                        }}
                      >
                        {provinceOptions.map((option) => (
                          <MenuItem
                            key={option.id}
                            value={option.id}
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label={gettext('Comments/Questions')}
                      variant={'outlined'}
                      multiline
                      value={personal.comments}
                      name={'comments'}
                      onChange={(e) => handlePersonalChange(e, personal)}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={10}>
            <Card raised>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography gutterBottom variant={'h6'}>
                      {gettext('Brochure')}
                    </Typography>

                    <Divider />
                  </Grid>

                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      label={gettext('Street')}
                      variant={'outlined'}
                      value={brochure?.street}
                      name={'street'}
                      onChange={(e) => handleBrochureChange(e, brochure)}
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      label={gettext('City')}
                      variant={'outlined'}
                      value={brochure?.city}
                      name={'city'}
                      onChange={(e) => handleBrochureChange(e, brochure)}
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <FormControl
                      variant={'outlined'}
                      fullWidth
                    >
                      <InputLabel>
                        {gettext('Province/Territory')}
                      </InputLabel>
                      <Select
                        label={gettext('Province/Territory')}
                        value={brochure?.province?.id ?? ''}
                        name={'id'}
                        // onChange={handleDelivery}
                        onChange={(e) => {
                          setBrochure({
                            ...brochure,
                            province: {
                              ...brochure.province,
                              id: e.target.value,
                              name: provinceOptions.find(option => option.id === e.target.value)?.label
                            }
                          });
                        }}
                      >
                        {provinceOptions.map((option) => (
                          <MenuItem
                            key={option.id}
                            value={option.id}
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      label={gettext('Postal Code')}
                      variant={'outlined'}
                      value={brochure?.postal_code}
                      name={'postal_code'}
                      onChange={(e) => handleBrochureChange(e, brochure)}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={10}>
            <Range
              initialState={range}
              link={request.link}
              onChange={handleRangeChange}
            />
          </Grid>

          <Grid item xs={10}>
            <Card raised>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography gutterBottom variant={'h6'} justifyContent={'space-between'} display={'flex'}>
                      {gettext('Accessories')}

                      <Button
                        onClick={() => {
                          const arr = [...accessories];
                          arr.push({
                            id: `${Date.now().valueOf()}`,
                            name: ''
                          });
                          setAccessories(arr);
                        }}
                      >
                        {gettext('+ Add accessory')}
                      </Button>
                    </Typography>

                    <Divider />
                  </Grid>

                  {accessories.length === 0 && (
                    <Grid item>
                      <Typography variant={'subtitle1'}>
                        {gettext('No accessories')}
                      </Typography>
                    </Grid>
                  )}

                  {accessories.map((accessory, index) => {
                    return (
                      <Grid item xs={12} key={accessory.id}>
                        <Grid container spacing={0}>
                          <Grid item xs={8}>
                            <PriceOption
                              optionLabel={gettext('Name')}
                              value={accessory}
                              onChange={(value) => {
                                const arr = [...accessories];
                                arr[index] = value;
                                setAccessories(arr);
                              }}
                            />
                          </Grid>

                          <Grid item xs={2} alignSelf={'center'}>
                            <IconButton
                              color={'error'}
                              onClick={() => {
                                const arr = [...accessories];
                                arr.splice(index, 1);
                                setAccessories(arr);
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={10}>
            <Card raised>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography gutterBottom variant={'h6'}>
                      {gettext('Hood')}
                    </Typography>

                    <Divider />
                  </Grid>

                  <Grid item xs={8}>
                    <PriceOption
                      optionLabel={gettext('Name')}
                      value={hood}
                      type={'select'}
                      options={
                        hoodOptions.map(option => {
                          if (option.id === 'hood_stainless_steel') {
                            option.label = range.color.name + ' and ' + option.label;
                          }
                          return option;
                        })}
                      onChange={(value) => {
                        if (value === null) {
                          setHood(null);
                        } else {
                          setHood({
                            ...hood,
                            ...value
                          });
                        }
                      }}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={10}>
            <Card raised>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography gutterBottom variant={'h6'}>
                      {gettext('Delivery')}
                    </Typography>

                    <Divider />
                  </Grid>

                  <Grid item xs={6}>
                    <FormControl
                      variant={'outlined'}
                      fullWidth
                    >
                      <InputLabel>
                        {gettext('Month')}
                      </InputLabel>
                      <Select
                        label={gettext('Month')}
                        value={delivery.date_month}
                        name={'date_month'}
                        onChange={handleDeliveryChange}
                      >
                        {monthOptions.map((option) => (
                          <MenuItem
                            key={option.id}
                            value={option.id}
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={6}>
                    <FormControl
                      variant={'outlined'}
                      fullWidth
                    >
                      <InputLabel>
                        {gettext('Year')}
                      </InputLabel>
                      <Select
                        label={gettext('Year')}
                        value={delivery.date_year}
                        name={'date_year'}
                        onChange={handleDeliveryChange}
                      >
                        {yearOptions.map((option) => (
                          <MenuItem
                            key={option.id}
                            value={option.id}
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={6}>
                    <PriceOption
                      optionLabel={gettext('Freight type')}
                      value={delivery.freight}
                      type={'select'}
                      options={freightOptions}
                      onChange={(value) => setDelivery({
                        ...delivery,
                        freight: value
                      })}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <PriceOption
                      optionLabel={gettext('White-Glove Delivery')}
                      value={delivery.wg_delivery}
                      type={'select'}
                      options={deliveryOptions}
                      onChange={(value) => setDelivery({
                        ...delivery,
                        wg_delivery: value
                      })}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={10}>
            <Card raised>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography gutterBottom variant={'h6'}>
                      {gettext('Calculation')}
                    </Typography>

                    <Divider />
                  </Grid>

                  <Grid item xs={12}>
                    <PriceOption
                      optionLabel={gettext('Rebate')}
                      value={rebate}
                      type={'select'}
                      options={rebateOptions}
                      disabledPrice={rebate?.id !== 'manual'}
                      onChange={(value) => {
                        if (value === null) {
                          setRebate(null);
                        } else {
                          setRebate({
                            ...rebate,
                            ...value
                          });
                        }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <PriceOption
                      optionLabel={gettext('Installation')}
                      value={request.installation}
                      type={'select'}
                      options={installationOptions}
                      onChange={(value) => setRequest({
                        ...request,
                        installation: value
                      })}
                    />
                  </Grid>

                  <Grid item xs={8} alignSelf={'center'}>
                    <Typography>
                      {gettext('Total estimated price before taxes (CAD $)')}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label={gettext('Price')}
                      variant={'outlined'}
                      value={totalEstimatedCost}
                      type={'number'}
                      disabled
                      InputProps={{
                        startAdornment:
                          <InputAdornment position="start">
                            $
                          </InputAdornment>
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <PriceOption
                      optionLabel={gettext('Tax, %')}
                      value={tax}
                      nameType={'number'}
                      disabledPrice={true}
                      onChange={handleTaxChange}
                    />
                  </Grid>

                  <Grid item xs={8} alignSelf={'center'}>
                    <Typography>
                      {gettext('Total Price including taxes')}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label={gettext('Price')}
                      variant={'outlined'}
                      value={totalCostWithTax}
                      type={'number'}
                      disabled
                      InputProps={{
                        startAdornment:
                          <InputAdornment position="start">
                            $
                          </InputAdornment>
                      }}
                    />
                  </Grid>

                  <Grid item xs={8} alignSelf={'center'}>
                    <Typography>
                      {gettext('40% deposit for order confirmation')}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label={gettext('Value')}
                      variant={'outlined'}
                      value={deposit}
                      type={'number'}
                      disabled
                      InputProps={{
                        startAdornment:
                          <InputAdornment position="start">
                            $
                          </InputAdornment>
                      }}
                    />
                  </Grid>

                  <Grid item xs={8} alignSelf={'center'}>
                    <Typography>
                      {gettext('Payment (before shipping)')}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label={gettext('Value')}
                      variant={'outlined'}
                      value={payment}
                      type={'number'}
                      disabled
                      InputProps={{
                        startAdornment:
                          <InputAdornment position="start">
                            $
                          </InputAdornment>
                      }}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid container item xs={10} justifyContent={'end'}>
            <Button
              variant="contained"
              onClick={() => handleSubmit()}
            >
              {gettext('Update request')}
            </Button>
          </Grid>
        </>
      )
      }
    </Grid>
  )
    ;
}

export default RequestDetailsPage;
