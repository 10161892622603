import styles from './Footnote.module.css';
import InfoIcon from '../assets/info.svg';
import { useLocales } from 'react-localized';
import cx from 'classnames';

function Footnote({ className }) {
  const { gettext } = useLocales();

  return (
    <div className={cx(styles.container, className)}>
      <img src={InfoIcon} alt="" />
      <p className={styles.label}>
        {gettext('Images for illustrative purposes only')}
      </p>
    </div>
  );
}

export default Footnote;
