import {
  Card,
  CardContent,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';
import PriceOption from './PriceOption';
import { useLocales } from 'react-localized';
import { useEffect, useState } from 'react';
import useOptions from '../utils/options';
import Link from '@mui/material/Link';

export default function Range({ initialState, link, onChange }) {
  const { heightOptions, gasOptions } = useOptions();
  const { gettext } = useLocales();
  const [range, setRange] = useState(initialState);

  useEffect(() => {
    onChange(range);
  }, [range]);
  const handleRangeChange = (e) => {
    const newRange = {
      ...range,
      [e.target.name]: e.target.value
    };
    setRange(newRange);
  };

  return (
    <Card raised>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography gutterBottom variant={'h6'} justifyContent={'space-between'} display={'flex'}>
              {gettext('Range')}

              <Link
                href={link}
                variant={'subtitle1'}
              >
                {link}
              </Link>
            </Typography>

            <Divider />
          </Grid>

          <Grid container spacing={1} item xs={12}>
            <Grid item xs={4}>
              <TextField
                fullWidth
                label={gettext('Name')}
                variant={'outlined'}
                value={range.name}
                name={'name'}
                onChange={handleRangeChange}
              />
            </Grid>

            <Grid item xs={4}>
              <TextField
                fullWidth
                label={gettext('Collection')}
                variant={'outlined'}
                value={range.collection}
                name={'collection'}
                onChange={handleRangeChange}
              />
            </Grid>

            <Grid item xs={2}>
              <TextField
                label={gettext('Price')}
                variant={'outlined'}
                value={range.base_price}
                type={'number'}
                InputProps={{
                  startAdornment:
                    <InputAdornment position="start">
                      $
                    </InputAdornment>
                }}
                onChange={(event) => {
                  setRange({
                    ...range,
                    base_price: event.target.value
                  });
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={1} item xs={12}>
            <Grid item xs={6}>
              <PriceOption
                optionLabel={gettext('Color')}
                value={range.color}
                onChange={(value) => setRange({
                  ...range,
                  color: value
                })}
              />
            </Grid>

            <Grid item xs={6}>
              <PriceOption
                optionLabel={gettext('Finishes')}
                value={range.finishes}
                onChange={(value) => setRange({
                  ...range,
                  finishes: value
                })}
              />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid container spacing={1} item xs={4}>
            <Grid item>
              <PriceOption
                optionLabel={gettext('Left Option 2')}
                value={range.left_option_2}
                onChange={(value) => setRange({
                  ...range,
                  left_option_2: value
                })}
              />
            </Grid>

            <Grid item>
              <PriceOption
                optionLabel={gettext('Left Option')}
                value={range.left_option}
                onChange={(value) => setRange({
                  ...range,
                  left_option: value
                })}
              />
            </Grid>

            <Grid item>
              <PriceOption
                optionLabel={gettext('Left Cupboard')}
                value={range.left_cupboard}
                onChange={(value) => setRange({
                  ...range,
                  left_cupboard: value
                })}
              />
            </Grid>
          </Grid>

          <Grid container spacing={1} item xs={4} alignContent={'center'}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label={gettext('Top')}
                variant={'outlined'}
                value={range.top?.name ?? ''}
                name={'top'}
                onChange={(e) => setRange({
                  ...range,
                  top: {
                    ...range.top,
                    name: e.target.value
                  }
                })}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label={gettext('Ovens')}
                variant={'outlined'}
                value={range.ovens?.name ?? ''}
                name={'ovens'}
                onChange={(e) => setRange({
                  ...range,
                  ovens: {
                    ...range.ovens,
                    name: e.target.value
                  }
                })}
              />
            </Grid>
          </Grid>

          <Grid container spacing={1} item xs={4}>
            <Grid item>
              <PriceOption
                optionLabel={gettext('Right Option 2')}
                value={range.right_option_2}
                onChange={(value) => setRange({
                  ...range,
                  right_option_2: value
                })}
              />
            </Grid>

            <Grid item>
              <PriceOption
                optionLabel={gettext('Right Option')}
                value={range.right_option}
                onChange={(value) => setRange({
                  ...range,
                  right_option: value
                })}
              />
            </Grid>

            <Grid item>
              <PriceOption
                optionLabel={gettext('Right Cupboard')}
                value={range.right_cupboard}
                onChange={(value) => setRange({
                  ...range,
                  right_cupboard: value
                })}
              />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid container spacing={1} item xs={12}>
            <Grid item xs={3}>
              <FormControl
                variant={'outlined'}
                fullWidth
              >
                <InputLabel>
                  {gettext('Gas Type')}
                </InputLabel>
                <Select
                  label={gettext('Gas Type')}
                  value={range.gas_type?.id ?? ''}
                  name={'id'}
                  onChange={(event) => setRange({
                    ...range,
                    gas_type: {
                      ...range.gas_type,
                      id: event.target.value,
                      name: gasOptions.find(option => option.id === event.target.value).label
                    }
                  })}
                >
                  {gasOptions.map((option) => (
                    <MenuItem
                      key={option.id}
                      value={option.id}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={2}>
              <TextField
                fullWidth
                label={gettext('Width')}
                variant={'outlined'}
                value={range.width}
                name={'width'}
                onChange={handleRangeChange}
              />
            </Grid>

            <Grid item xs={3}>
              <FormControl
                variant={'outlined'}
                fullWidth
              >
                <InputLabel>
                  {gettext('Height')}
                </InputLabel>
                <Select
                  label={gettext('Height')}
                  value={range.height?.id ?? ''}
                  name={'id'}
                  onChange={(event) => setRange({
                    ...range,
                    height: {
                      ...range.height,
                      id: event.target.value
                    }
                  })}
                >
                  {heightOptions.map((option) => (
                    <MenuItem
                      key={option.id}
                      value={option.id}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={2}>
              <TextField
                fullWidth
                label={gettext('Total BTU')}
                variant={'outlined'}
                value={range.total_btu}
                name={'total_btu'}
                type={'number'}
                onChange={handleRangeChange}
              />
            </Grid>

            <Grid item xs={2}>
              <TextField
                fullWidth
                label={gettext('Total Amps')}
                variant={'outlined'}
                value={range.total_amps}
                name={'total_amps'}
                type={'number'}
                onChange={handleRangeChange}
              />
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}