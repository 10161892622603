import { signIn } from '../utils/api';

const tokenKey = 'token';
const userKey = 'user';

const login = (credentials) => {
  return signIn(credentials)
    .then(response => {
      updateToken(response.token);
      localStorage.setItem(userKey, JSON.stringify(response.user));
      window.location.replace('dashboard');
    });
};

const logout = () => {
  updateToken(null);
  localStorage.removeItem(userKey);
  window.location.reload();
};

const getCurrentUser = () => {
  try {
    if (localStorage.getItem(userKey)) {
      return JSON.parse(localStorage.getItem(userKey));
    }
  } catch (Exception) {
    // todo add logging
  }

  return '';
};
const getAccessToken = () => {
  if (localStorage.getItem(tokenKey)) {
    return JSON.parse(localStorage.getItem(tokenKey)).accessToken;
  }
  return '';
};

const updateToken = (token) => {
  if (token) {
    localStorage.setItem(tokenKey, JSON.stringify(token));
  } else {
    localStorage.removeItem(tokenKey);
  }
};

export const AuthenticationService = {
  login,
  logout,
  getCurrentUser,
  getAccessToken,
  updateToken
};
