import { useLocales } from "react-localized";


function useOptions() {
  const { gettext } = useLocales();

  const gasOptions = [
    {
      id: "natural",
      label: gettext("Natural gas")
    },
    {
      id: "propane",
      label: gettext("Propane")
    },
    {
      id: "none",
      label: gettext("Not sure yet")
    }
  ];

  const heightOptions = [
    {
      id: "standard",
      label: gettext("36\" (standard)")
    },
    {
      id: "something",
      label: gettext("36-5/8\" (no additional charge)")
    }
  ];

  const provinceOptions = [
    {
      id: "ab",
      label: gettext("Alberta")
    },
    {
      id: "bc",
      label: gettext("British Columbia")
    },
    {
      id: "mb",
      label: gettext("Manitoba")
    },
    {
      id: "nb",
      label: gettext("New Brunswick")
    },
    {
      id: "nl",
      label: gettext("Newfoundland and Labrador")
    },
    {
      id: "ns",
      label: gettext("Nova Scotia")
    },
    {
      id: "on",
      label: gettext("Ontario")
    },
    {
      id: "pe",
      label: gettext("Prince Edward Island")
    },
    {
      id: "qc",
      label: gettext("Quebec")
    },
    {
      id: "sk",
      label: gettext("Saskatchewan")
    },
    {
      id: "nt",
      label: gettext("Northwest Territories")
    },
    {
      id: "nu",
      label: gettext("Nunavut")
    },
    {
      id: "yt",
      label: gettext("Yukon")
    },
    {
      id: "us",
      label: gettext("USA / Other")
    }
  ];

  const monthOptions = [
    {
      id: "1",
      label: gettext("January")
    },
    {
      id: "2",
      label: gettext("February")
    },
    {
      id: "3",
      label: gettext("March")
    },
    {
      id: "4",
      label: gettext("April")
    },
    {
      id: "5",
      label: gettext("May")
    },
    {
      id: "6",
      label: gettext("June")
    },
    {
      id: "7",
      label: gettext("July")
    },
    {
      id: "8",
      label: gettext("August")
    },
    {
      id: "9",
      label: gettext("September")
    },
    {
      id: "10",
      label: gettext("October")
    },
    {
      id: "11",
      label: gettext("November")
    },
    {
      id: "12",
      label: gettext("December")
    }
  ];

  const deliveryOptions = [
    {
      id: "1",
      label: gettext("28\" to 60\" models (uncrate, unpack, place in kitchen)"),
      cost: 650
    },
    {
      id: "2",
      label: gettext("71\" to 87\" models (uncrate, unpack, place in kitchen)"),
      cost: 950
    },
    {
      id: "3",
      label: gettext("WG delivery outside of metropolitan areas: 28\" to 60\""),
      cost: 1450
    },
    {
      id: "4",
      label: gettext("WG delivery outside of metropolitan areas: 71\" to 87\""),
      cost: 1650
    }
  ];

  const installationOptions = [
    {
      id: "",
      label: gettext("None")
    },
    {
      id: "1",
      label: gettext("28\" to 40\" models (range only)"),
      cost: 450
    },
    {
      id: "2",
      label: gettext("28\" to 40\" models (range + hood)"),
      cost: 750
    },
    {
      id: "3",
      label: gettext("44\" to 60\" models (range only)"),
      cost: 550
    },
    {
      id: "4",
      label: gettext("44\" to 60\" models (range + hood)"),
      cost: 850
    },
    {
      id: "5",
      label: gettext("71\" and 87\" models (range only)"),
      cost: 650
    },
    {
      id: "6",
      label: gettext("71\" and 87\" models (range + hood)"),
      cost: 950
    }
  ];

  const rebateOptions = [
    {
      id: "",
      label: gettext("None")
    },
    {
      id: "auto",
      label: gettext("Preferred Customer Rebate (5% before shipping / delivery costs)"),
      cost: 0
    },
    {
      id: "manual",
      label: gettext("Preferred Customer Rebate – other"),
      cost: 0
    }
  ];

  const rangeOptions = [
    {
      id: "top",
      label: gettext("Top"),
      not_price: true
    },
    {
      id: "ovens",
      label: gettext("Ovens"),
      not_price: true
    },
    {
      id: "color",
      label: gettext("Color")
    },
    {
      id: "finishes",
      label: gettext("Finishes")
    },
    {
      id: "left_option_2",
      label: gettext("Left Option 2")
    },
    {
      id: "left_option",
      label: gettext("Left Option")
    },
    {
      id: "right_option",
      label: gettext("Right Option")
    },
    {
      id: "right_option_2",
      label: gettext("Right Option 2")
    },
    {
      id: "left_cupboard",
      label: gettext("Left Cupboard")
    },
    {
      id: "right_cupboard",
      label: gettext("Right Cupboard")
    }
  ];

  const rolesOptions = [
    {
      id: "admin",
      label: "Admin"
    },
    {
      id: "staff",
      label: "Staff"
    }
  ];

  const freightOptions = [
    {
      id: "ocean",
      label: "Ocean Freight"
    },
    {
      id: "air",
      label: "Air Freight"
    }
  ];

  const accessoryOptions = [
    {
      id: "accessory_smooth_griddle",
      label: "Smooth Griddle",
      cost: 0
    },
    {
      id: "accessory_simmer_plate",
      label: "Simmer Plate",
      cost: 0
    },
    {
      id: "accessory_simmer_plate_cover_(tradition_top)",
      label: "Simmer Plate Cover (Tradition Top)",
      cost: 0
    },
    {
      id: "accessory_cutting_board",
      label: "Cutting Board",
      cost: 0
    },
    {
      id: "accessory_ridged_griddle",
      label: "Ridged Griddle",
      cost: 0
    }
  ];

  const hoodOptions = [
    {
      id: "",
      label: "None",
      cost: 0
    },
    {
      id: "hood_stainless_steel",
      label: "Stainless Steel (standard)",
      cost: 0
    },
    {
      id: "hood_upper_extension_in_matching_color",
      label: "Upper Extension in matching color (custom)",
      cost: 0
    },
    {
      id: "hood_lower_beam_in_matching_color",
      label: "Lower Beam in matching color (custom)",
      cost: 0
    },
    {
      id: "hood_all_in_matching_color",
      label: "All in matching color (custom)",
      cost: 0
    },
    {
      id: "hood_custom",
      label: "Custom hood",
      cost: 0
    }
  ];

  const languageOptions = [
    {
      id: "en",
      label: "English"
    },
    {
      id: "fr",
      label: "Français"
    }
  ];

  return {
    monthOptions,
    provinceOptions,
    gasOptions,
    heightOptions,
    deliveryOptions,
    installationOptions,
    rebateOptions,
    rangeOptions,
    rolesOptions,
    freightOptions,
    accessoryOptions,
    hoodOptions,
    languageOptions
  };
}

export default useOptions;