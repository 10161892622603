import { useLocales } from 'react-localized';

import Modal from 'components/Modal';
import Button from 'components/Button';
import Spinner from 'components/Spinner';

import styles from './PendingModal.module.css';

import icon from 'assets/done-check.svg';
import { useLocation, useNavigate } from 'react-router-dom';

function PendingModal({ pending }) {
  const { gettext } = useLocales();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Modal className={styles.container}>
      {pending && (
        <>
          <Spinner />
          <div className={styles.label}>
            {gettext('Your request is sending...')}
          </div>
        </>
      )}
      {!pending && (
        <>
          <img src={icon} alt="" />
          <div className={styles.label}>
            {gettext('Your request has been sent!')}
          </div>
          <div className={styles.actions}>
            <Button
              filled
              onClick={() => {
                navigate({
                  pathname: '/',
                  search: location.search
                });
              }}
            >
              {gettext('Back to configurator')}
            </Button>
            <Button
              onClick={() => {
                window.location = 'https://www.french-barn.com/';
              }}
            >
              {gettext('Main page')}
            </Button>
          </div>
        </>
      )}
    </Modal>
  );
}

export default PendingModal;
