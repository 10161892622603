import cx from 'classnames';

import Check from 'assets/confirmed-check.svg';
import Circle from 'assets/confirmed-circle.svg';
import styles from './TextElementButton.module.css';

function TextElementButton({ className, selected, label, disabled, onClick }) {
  return (
    <div
      className={cx(
        styles.container,
        selected && styles.selected,
        disabled && styles.disabled,
        className,
      )}
      onClick={(event) => {
        if (disabled) {
          return;
        }
        onClick?.(event);
      }}
    >
      <div className={styles.label}>{label}</div>
      <img src={selected ? Check : Circle} alt="" />
    </div>
  );
}

export default TextElementButton;
