import styles from './SectionList.module.css';

function Section({ label }) {
  return (
    <div className={styles.section}>
      {label}
      <div className={styles.sectionLine} />
    </div>
  );
}

function SectionList({ children }) {
  return <div className={styles.list}>{children}</div>;
}

SectionList.Section = Section;

export default SectionList;
