import cx from 'classnames';

import RenderAboveEverything from './RenderAboveEverything';

import styles from './Modal.module.css';

function Modal({ className, children }) {
  return (
    <RenderAboveEverything className={styles.container}>
      <div className={cx([styles.body, className])}>{children}</div>
    </RenderAboveEverything>
  );
}

export default Modal;
