import cx from 'classnames';
import { forwardRef } from 'react';

import styles from './RoundButton.module.css';

function RoundButton({ children, selected, imageSrc, ...otherProps }, ref) {
  return (
    <button
      ref={ref}
      className={cx(styles.button, selected && styles.selected)}
      style={{ backgroundImage: `url(${imageSrc})` }}
      {...otherProps}
    >
      {children}
    </button>
  );
}

export default forwardRef(RoundButton);
