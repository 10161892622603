import ElementButton from './ElementButton';
import styles from './ImageElementButton.module.css';

function ImageElementButton({ src, ...otherProps }) {
  return (
    <ElementButton {...otherProps}>
      {src !== '...' && <img className={styles.image} src={src} alt="" />}
      {otherProps.selected && <div className={styles.confirmedOverlay} />}
    </ElementButton>
  );
}

export default ImageElementButton;
