import mapValues from 'lodash/mapValues';

import LayeredCanvas from 'components/LayeredCanvas';

import { evaluate } from 'utils/templates';

function RangePreview({
  className,
  rootConfig,
  rangeConfig,
  range,
  options,
  view,
}) {
  const variant = rangeConfig[view.variant_id];

  const variantSpecificOptions = mapValues(options, (prevOption, layerId) => {
    const layer = variant.layers.find((layer) => layer.id === layerId);
    if (!layer) {
      return { id: prevOption.id, missing: true };
    }

    const option = layer.options.find((option) => option.id === prevOption.id);
    return option;
  });

  return (
    <LayeredCanvas
      className={className}
      imageSize={rootConfig.imageSize}
      pixelMargin={variant.margin}
      layers={variant.layers
        .filter((layer) => {
          if (layer.target) {
            return false;
          }
          if (!layer.render_condition) {
            return true;
          }
          return (
            evaluate(layer.render_condition, {
              range,
              view,
              variant,
              layer,
              option: variantSpecificOptions[layer.id],
              options: variantSpecificOptions,
            }) === 'true'
          );
        })
        .map((layer) => ({
          url: evaluate(
            layer.image_template || variantSpecificOptions[layer.id].image,
            {
              range,
              view,
              variant,
              layer,
              option: variantSpecificOptions[layer.id],
              options: variantSpecificOptions,
            },
          ),
        }))}
    />
  );
}

export default RangePreview;
