import { useSelect } from 'downshift';
import cx from 'classnames';

import styles from './Dropdown.module.css';

function Dropdown({ label, value, name, placeholder, onChange, options, className, required }) {
  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    highlightedIndex,
    getItemProps
  } = useSelect({
    items: options,
    defaultSelectedItem: value ? options.find((o) => o.id === value) : null,
    onSelectedItemChange: onChange
  });

  return (
    <div className={styles.container}>
      <label className={styles.label} {...getLabelProps()}>
        {label}
        {required && ' *'}
      </label>
      <input
        className={cx(styles.input, className, isOpen && styles.open)}
        placeholder={placeholder}
        value={selectedItem ? selectedItem.label : ''}
        required={required}
        readOnly
        {...getToggleButtonProps()}
      />
      <div
        className={cx(styles.dropdown, isOpen && styles.open)}
        {...getMenuProps()}
      >
        {options.map((item, index) => (
          <div
            key={`${item}${index}`}
            className={cx(
              styles.dropdownItem,
              highlightedIndex === index && styles.highlighted
            )}
            {...getItemProps({ item, index })}
          >
            {item.label}
          </div>
        ))}
      </div>
      <input
        type="hidden"
        name={name}
        value={selectedItem ? selectedItem.id : ''}
      />
    </div>
  );
}

export default Dropdown;
