import cx from 'classnames';
import { useEffect, useState } from 'react';

import Check from 'assets/confirmed-check.svg';
import Circle from 'assets/confirmed-circle.svg';
import styles from './FormImageOption.module.css';

function FormImageOption({
                           className,
                           imageUrl,
                           label,
                           name,
                           onClick,
                           isSelected
                         }) {
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    setSelected(isSelected);
  }, [isSelected]);

  const onSelect = (name) => {
    setSelected(!selected);

    if (onClick) {
      onClick({
        id: name,
        name: label
      });
    }
  };

  return (
    <div
      className={cx(styles.container, selected && styles.selected, className)}
      role="button"
      onClick={() => onSelect(name)}
    >
      <img src={selected ? Check : Circle} alt="" />
      <div className={styles.label}>{label}</div>
      <img className={styles.cover} src={imageUrl} alt="" />
      <input type="hidden" name={name} value={selected ? 'true' : 'false'} />
    </div>
  );
}

export default FormImageOption;
