import cx from 'classnames';

import styles from './Button.module.css';

function Button({ className, filled, children, ...otherProps }) {
  return (
    <button
      className={cx(
        className,
        styles.button,
        filled && styles.filled,
        otherProps.disabled && styles.disabled,
      )}
      {...otherProps}
    >
      {children}
    </button>
  );
}

export default Button;
