import { useEffect, useState } from 'react';
import { useLocales } from 'react-localized';
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import useOptions from 'utils/options';
import { addUser, fetchUserById, updateUser } from '../utils/api';
import { useNotification } from '../provider/NotificationProvider';

function RequestDetailsPage() {
  const { gettext } = useLocales();
  const { showNotification } = useNotification();
  const { rolesOptions } = useOptions();
  const navigate = useNavigate();

  const [user, setUser] = useState({
    // id: '',
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    password: '',
    roles: []
  });

  const [action, setAction] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const { id } = useParams();

  useEffect(() => {
    if (id === 'new') {
      setAction('new');
      setIsLoading(false);
    } else {
      fetchUserById(id)
        .then(response => {
          setUser(fillUser(response));
          setIsLoading(false);
        })
        .catch(error => {
            setIsLoading(false);
            showNotification('Failed to fetch user', 'error');
          }
        );
    }
  }, []);

  const handleSubmit = () => {
    const data = {
      id: user.id,
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      phone: user.phone,
      password: user.password,
      roles: user.roles,
      status: user.status
    };

    setIsLoading(true);

    if (action) {
      addUser(data)
        .then(response => {
          showNotification('Success', 'success');
          navigate(-1);
        })
        .catch(error => {
            setIsLoading(false);
            showNotification('Failed to add user', 'error');
          }
        );
    } else {
      updateUser(data)
        .then(response => {
          setIsLoading(false);
          showNotification('Success', 'success');
        })
        .catch(error => {
            setIsLoading(false);
            showNotification('Failed to update user', 'error');
          }
        );
    }
  };

  const fillUser = (user) => {
    return {
      id: user.id,
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      phone: user.phone,
      roles: user.roles,
      status: user.status
    };
  };

  const handleUserChange = (event) => {
    setUser({
      ...user,
      [event.target.name]: event.target.value
    });
  };

  return (
    <Grid container spacing={2} justifyContent={'center'}>
      {isLoading && (
        <Box sx={{ height: '100vh', display: 'flex', alignItems: 'center' }}>
          <CircularProgress />
        </Box>
      )}

      {!isLoading && (
        <>
          <Grid item xs={12}>
            <Typography
              align={'center'}
              variant={'h4'}
            >
              {gettext('User details')}
            </Typography>
          </Grid>

          <Grid item xs={10}>
            <Card raised>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography gutterBottom variant={'h6'}>
                      {gettext('Personal')}
                    </Typography>

                    <Divider />
                  </Grid>

                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      label={gettext('First Name')}
                      variant={'outlined'}
                      value={user.first_name}
                      name={'first_name'}
                      onChange={handleUserChange}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      label={gettext('Last Name')}
                      variant={'outlined'}
                      value={user.last_name}
                      name={'last_name'}
                      onChange={handleUserChange}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      label={gettext('Email')}
                      variant={'outlined'}
                      type={'email'}
                      value={user.email}
                      disabled={!action}
                      name={'email'}
                      onChange={handleUserChange}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      label={gettext('Phone Number')}
                      variant={'outlined'}
                      type={'tel'}
                      value={user.phone}
                      name={'phone'}
                      onChange={handleUserChange}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      label={gettext('Password')}
                      variant={'outlined'}
                      type={'password'}
                      value={user.password}
                      name={'password'}
                      onChange={handleUserChange}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <FormControl
                      variant={'outlined'}
                      fullWidth
                    >
                      <InputLabel>
                        {gettext('Roles')}
                      </InputLabel>
                      <Select
                        label={gettext('Roles')}
                        value={user.roles}
                        multiple
                        renderValue={(selected) => selected.join(', ')}
                        name={'roles'}
                        onChange={handleUserChange}
                      >
                        {rolesOptions.map((option) => (
                          <MenuItem
                            key={option.id}
                            value={option.id}
                          >
                            <Checkbox checked={user.roles.indexOf(option.id) > -1} />
                            <ListItemText primary={option.label} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid container item xs={10} justifyContent={'end'}>
            <Button
              variant="contained"
              onClick={() => handleSubmit()}
            >
              {action ? gettext('Add') : gettext('Update')}
            </Button>
          </Grid>
        </>
      )}
    </Grid>
  );
}

export default RequestDetailsPage;
