import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import RangeConfigPage from "../components/RangeConfigPage";
import SignInPage from "../page/SignInPage";
import RangeSummaryPage from "../page/RangeSummaryPage";
import RangeQuotePage from "../page/RangeQuotePage";
import LayoutPage from "../page/LayoutPage";
import RequestsPage from "../page/RequestsPage";
import RequestDetailsPage from "../page/RequestDetailsPage";
import UsersPage from "../page/UsersPage";
import UserDetailsPage from "../page/UserDetailsPage";
import Authorization from "../auth/Authorization";
import PERMISSIONS from "../permissions/Permissions";
import Authentication from "../auth/Authentication";

const RoutePath = ({ store }) => {
  return (
    <Routes>
      <Route path="/" element={<RangeConfigPage store={store} />} />
      <Route path="/summary" element={<RangeSummaryPage />} />
      <Route path="/quote" element={<RangeQuotePage />} />
      <Route
        path="dashboard"
        element={
          <Authentication>
            <LayoutPage />
          </Authentication>
        }>
        <Route element={<Authorization permissions={[PERMISSIONS.CAN_VIEW_REQUESTS]} />}>
          <Route path="requests" element={<RequestsPage />} />
          <Route path="requests/:id" element={<RequestDetailsPage />} />
        </Route>
        <Route element={<Authorization permissions={[PERMISSIONS.CAN_VIEW_USERS]} />}>
          <Route path="users" element={<UsersPage />} />
          <Route path="users/:id" element={<UserDetailsPage />} />
        </Route>
      </Route>
      <Route path="/login" element={<SignInPage />} />
      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  );
};

export default RoutePath;
