import { useLocales } from 'react-localized';
import {
  Grid,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useNotification } from '../provider/NotificationProvider';
import { fetchRequests } from '../utils/api';

function RequestsPage() {
  const { gettext } = useLocales();
  const navigate = useNavigate();
  const { showNotification } = useNotification();

  const [isLoading, setIsLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('created_at');
  const [requests, setRequests] = useState([]);

  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    fetch();
  }, [page, rowsPerPage, orderBy, order, searchValue]);

  const fetch = () => {
    fetchRequests(page, rowsPerPage, orderBy, order, searchValue)
      .then(response => {
        setTotalCount(response.total_count);
        setRequests(convert(response.requests));
        setIsLoading(false);
      })
      .catch(error => {
          setIsLoading(false);
          showNotification('Failed to fetch requests', 'error');
        }
      );
  };

  const convert = (requests) => {
    return requests.map(request => {
      return {
        'id': request.id,
        'first_name': request.first_name,
        'last_name': request.last_name,
        'email': request.email,
        'phone': request.phone,
        'status': request.status,
        'created_at': new Date(Date.parse(request.created_at)).toLocaleString(),
        'range_name': request.range.name
      };
    });
  };


  const handleRowClick = (event, requestId) => {
    navigate(`${requestId}`);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSort = (id) => {
    setOrderBy(id);

    if (order === 'asc') {
      setOrder('desc');
    } else {
      setOrder('asc');
    }

  };

  const columnNames = [
    {
      id: 'range_name',
      label: gettext('Range'),
      sort: false
    },
    {
      id: 'first_name',
      label: gettext('First Name'),
      sort: true
    },
    {
      id: 'last_name',
      label: gettext('Last Name'),
      sort: true
    },
    {
      id: 'email',
      label: gettext('Email'),
      sort: true
    },
    {
      id: 'phone',
      label: gettext('Phone'),
      sort: true
    },
    {
      id: 'created_at',
      label: gettext('Created'),
      sort: true
    },
    {
      id: 'status',
      label: gettext('Status'),
      sort: false
    }
  ];

  return (
    <Grid item container spacing={2}>
      <Grid item xs={4}>
        <TextField
          fullWidth
          size={'small'}
          label={gettext('Search by first name, last name, email and phone')}
          variant={'outlined'}
          value={searchValue}
          name={'searchValue'}
          onChange={(e) => setSearchValue(e.target.value)}
        />
      </Grid>

      <Grid item xs={12}>
        {isLoading && <LinearProgress />}

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {columnNames.map((column, index) => (
                  <TableCell
                    key={column.id}
                    align={index === 0 ? 'left' : 'center'}
                    // sortDirection={orderBy === column.id ? order : false}
                  >
                    <TableSortLabel
                      disabled={!column.sort}
                      active={orderBy === column.id}
                      direction={orderBy === column.id ? order : 'asc'}
                      onClick={() => handleSort(column.id)}
                    >
                      {column.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {requests.map((row) => (
                <TableRow
                  key={row.id}
                  hover
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  onClick={(event) => handleRowClick(event, row.id)}
                >
                  {columnNames.map((column, index) => (
                    <TableCell
                      key={column.id}
                      align={index === 0 ? 'left' : 'center'}
                    >
                      {row[column.id]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Grid>
    </Grid>
  );
}

export default RequestsPage;
