import { useLocales } from 'react-localized';
import {
  Button,
  Grid,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchUsers } from '../utils/api';
import useOptions from '../utils/options';
import { useNotification } from '../provider/NotificationProvider';

function UsersPage() {
  const { gettext } = useLocales();
  const navigate = useNavigate();
  const { showNotification } = useNotification();
  const { rolesOptions } = useOptions();

  const [isLoading, setIsLoading] = useState(true);

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    fetchUsers()
      .then(response => {
        setUsers(convert(response.users));
        setIsLoading(false);
      })
      .catch(error => {
          setIsLoading(false);
          showNotification('Failed to fetch users', 'error');
        }
      );
  }, []);

  const convert = (users) => {
    return users.map(user => {
      return {
        'id': user.id,
        'first_name': user.first_name,
        'last_name': user.last_name,
        'email': user.email,
        'phone': user.phone,
        'status': user.status,
        'roles': user.roles
          .map(role => rolesOptions.find(option => option.id === role).label)
          .join(', ')
      };
    });
  };


  const handleRowClick = (event, userId) => {
    navigate(`${userId}`);
  };

  const handleAddUser = (event) => {
    navigate('new');
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const columnNames = [
    {
      id: 'first_name',
      label: gettext('First Name')
    },
    {
      id: 'last_name',
      label: gettext('Last Name')
    },
    {
      id: 'email',
      label: gettext('Email')
    },
    {
      id: 'phone',
      label: gettext('Phone')
    },
    {
      id: 'roles',
      label: gettext('Roles')
    },
    {
      id: 'status',
      label: gettext('Status')
    }
  ];

  return (
    <Grid item container spacing={2}>
      <Grid item xs={12}>
        <Button
          variant="contained"
          onClick={() => handleAddUser()}
        >
          {gettext('Add User')}
        </Button>
      </Grid>

      <Grid item xs={12}>
        {isLoading && <LinearProgress />}

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {columnNames.map((column, index) => (
                  <TableCell
                    key={column.id}
                    align={index === 0 ? 'left' : 'center'}
                    component="th"
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {users
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow
                    key={row.id}
                    hover
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    onClick={(event) => handleRowClick(event, row.id)}
                  >
                    {columnNames.map((column, index) => (
                      <TableCell
                        key={column.id}
                        align={index === 0 ? 'left' : 'center'}
                      >
                        {row[column.id]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={users.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Grid>
    </Grid>
  );
}

export default UsersPage;
