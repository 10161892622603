import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthenticationService } from '../services/AuthenticationService';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const redirectPath = location.state?.path;
  const search = location.search;

  const [user, setUser] = useState(null);
  const currentUser = AuthenticationService.getCurrentUser();

  useEffect(() => {
    if (currentUser) {
      login(currentUser);
    } else {
      logout();
    }
  }, []);

  const login = (user) => {
    if (user.roles.includes('admin')) {
      setUser({
        ...user,
        permissions: ['view_requests', 'view_users']
      });
    } else {
      setUser({
        ...user,
        permissions: ['view_requests']
      });
    }
    navigate({
      pathname: redirectPath,
      search: search
    });
    // navigate(redirectPath, { replace: true });
  };
  const logout = () => {
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ user }}>
      {children}
    </AuthContext.Provider>
  );
};
export const useAuth = () => {
  return useContext(AuthContext);
};
