import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from '../provider/AuthProvider';
import UnauthorizedPage from '../page/UnauthorizedPage';

const Authorization = ({ permissions }) => {
  const { user } = useAuth();
  const location = useLocation();

  if (!user) {
    return <Navigate to="/login" state={{ path: location.pathname }} replace />;
  }

  const userPermission = user.permissions;
  const isAllowed = permissions.some((allowed) => userPermission.includes(allowed));
  return isAllowed ? <Outlet /> : <UnauthorizedPage />;
};

export default Authorization;
