import PageWrapper from "components/PageWrapper";
import RangeView from "components/RangeView";

function RangeConfigPage({ store }) {
  return (
    <PageWrapper store={store}>
      <RangeView store={store} />
    </PageWrapper>
  );
}

export default RangeConfigPage;
