import { useEffect, useRef, useState } from 'react';
import { useLocales } from 'react-localized';
import AnimateHeight from 'react-animate-height';
import cx from 'classnames';

import ExpandIcon from 'assets/expand.svg';
import CollapseIcon from 'assets/collapse.svg';
import WarningIcon from 'assets/warning-empty.svg';

import styles from './ElementList.module.css';

function ElementList({
                       expandable,
                       selected,
                       warning,
                       label,
                       selectionLabel,
                       hideSelection,
                       addon,
                       children,
                       onExpanded
                     }) {
  const { gettext } = useLocales();
  const containerElement = useRef(null);
  const wasSelected = useRef(false);

  const [expanded, setExpanded] = useState(false);
  const actuallyExpanded = expandable && expanded;

  const scrollIntoView = () => {
    const child = containerElement.current;
    const parent = child.parentNode;
    const stretchElement = parent.children.namedItem('stretchElement');
    stretchElement.style.height = '0px';
    const { scrollHeight, clientHeight } = parent;
    const offsetTop = child.offsetTop;
    const height = clientHeight - (scrollHeight - offsetTop);

    if (height > 0) {
      stretchElement.style.height = `${height}px`;
    }

    parent.scrollTo({
      behavior: 'smooth',
      top: offsetTop
    });
  };

  useEffect(() => {
    if (selected && !wasSelected.current) {
      setExpanded(true);
      setTimeout(scrollIntoView, 100);
    }
    wasSelected.current = selected;
  }, [selected]);

  return (
    <div
      ref={containerElement}
      className={cx(
        styles.container,
        expanded && styles.expanded,
        expandable && styles.expandable,
        hideSelection && styles.hideSelection
      )}
    >
      <div
        className={styles.header}
        onClick={() => {
          if (!expandable) {
            return;
          }
          setExpanded((v) => !v);
          onExpanded?.(!expanded);
        }}
      >
        <img
          className={styles.expandButton}
          src={expanded ? CollapseIcon : ExpandIcon}
          alt=""
        />
        <div className={styles.headerColumn}>
          <div className={styles.headerLabel}>{label}</div>
          <div
            className={cx(
              styles.selectionLabel,
              selectionLabel && styles.hasSelection
            )}
          >
            {selectionLabel || gettext('Please choose the option')}
          </div>
        </div>
        {addon}
        {warning && <img className={styles.warning} src={WarningIcon} alt="" />}
      </div>
      <AnimateHeight
        className={styles.content}
        duration={500}
        height={actuallyExpanded ? 'auto' : 0}
      >
        {children}
      </AnimateHeight>
    </div>
  );
}

export default ElementList;
