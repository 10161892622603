import * as React from 'react';
import { useState } from 'react';

import { useLocales } from 'react-localized';
import { Container, Grid, MenuItem, MenuList, Typography } from '@mui/material';
import Bar from '../components/Bar';
import { Outlet, useNavigate } from 'react-router-dom';
import styles from './LayoutPage.module.css';
import { useAuth } from '../provider/AuthProvider';

function LayoutPage() {
  const { gettext } = useLocales();
  const { user } = useAuth();
  const navigate = useNavigate();

  const [selectedIndex, setSelectedIndex] = useState('');

  const options = [
    {
      'name': gettext('Configurator'),
      'path': '/',
      'permission': 'view_requests'
    },
    {
      'name': gettext('Requests'),
      'path': 'requests',
      'permission': 'view_requests'
    },
    {
      'name': gettext('Users'),
      'path': 'users',
      'permission': 'view_users'
    }
  ];

  const handleMenuItemClick = (path, index) => {
    setSelectedIndex(index);
    navigate(path);
  };

  return (
    <Container maxWidth={false}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Bar />
        </Grid>
        <Grid item xs={2}>
          <MenuList className={styles.menu}>
            {options
              .filter(option => user?.permissions.includes(option.permission))
              .map((option, index) => (
                <MenuItem
                  key={option.name}
                  selected={index === selectedIndex}
                  onClick={(event) => handleMenuItemClick(option.path, index)}
                >
                  <Typography variant="inherit" noWrap>
                    {option.name}
                  </Typography>
                </MenuItem>
              ))}
          </MenuList>
        </Grid>
        <Grid item xs={10}>
          <Outlet />
        </Grid>
      </Grid>
    </Container>
  );
}

export default LayoutPage;
