import { FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useLocales } from 'react-localized';

function PriceOption({
                       optionLabel,
                       value,
                       disabledPrice,
                       type = 'input',
                       nameType = 'text',
                       options = [],
                       adornment = '$',
                       onChange
                     }) {
  const { gettext } = useLocales();
  const _value = value ?? { id: '', name: '', price: 0, ...value };

  const handleInputChange = (e) => {
    onChange({
      ..._value,
      [e.target.name]: e.target.value
    });
  };

  const handlePriceInputChange = (e) => {
    onChange({
      ..._value,
      [e.target.name]: parseFloat(e.target.value)
    });
  };

  const handleSelectChange = (e) => {
    if (e.target.value) {
      const option = options.find(option => option.id === e.target.value);
      onChange({
        ..._value,
        name: option.label,
        [e.target.name]: e.target.value,
        price: (option.cost || 0)
        // price: _value.price ? _value.price : (option.cost || 0)
      });
    } else {
      onChange(null);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={8}>
        {type === 'select' ? (
          <FormControl
            variant={'outlined'}
            fullWidth
          >
            <InputLabel>
              {optionLabel}
            </InputLabel>
            <Select
              label={optionLabel}
              value={_value.id}
              name={'id'}
              onChange={handleSelectChange}
            >
              {options.map((option) => (
                <MenuItem
                  key={option.id}
                  value={option.id}
                >
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <TextField
            fullWidth
            label={optionLabel}
            variant={'outlined'}
            value={_value.name}
            type={nameType}
            name={'name'}
            onChange={handleInputChange}
          />
        )}
      </Grid>

      <Grid item xs={4}>
        <TextField
          label={gettext('Price')}
          variant={'outlined'}
          value={_value.price ?? 0}
          type={'number'}
          name={'price'}
          disabled={!_value?.name || _value?.name === '' || disabledPrice || _value.id === 'none'}
          InputProps={{
            startAdornment:
              <InputAdornment position="start">
                {adornment}
              </InputAdornment>
          }}
          onChange={handlePriceInputChange}
        />
      </Grid>
    </Grid>
  );
}

export default PriceOption;
