import { Fragment, useState } from "react";
import { useLocales } from "react-localized";
import groupBy from "lodash/groupBy";
import cx from "classnames";

import Link from "./Link";
import Button from "./Button";
import Tooltip from "./Tooltip";
import RoundButton from "./RoundButton";
import SquareCheckbox from "./SquareCheckbox";
import LayeredCanvas from "./LayeredCanvas";
import TextElementButton from "./TextElementButton";
import ColorElementButton from "./ColorElementButton";
import ImageElementButton from "./ImageElementButton";
import SectionList from "./SectionList";
import ElementList from "./ElementList";
import { evaluate } from "utils/templates";

import InfoIcon from "assets/info.svg";
import HoodPreview from "assets/hood-preview.png";

import styles from "./RangeView.module.css";
import Footnote from "./Footnote";
import { createSearchParams, useNavigate } from "react-router-dom";
import qs from "qs";

function RangeView({ store }) {
  const { gettext } = useLocales();
  const navigate = useNavigate();
  const [showMarkers, setShowMarkers] = useState(true);
  const { state, actions } = store;

  const {
    rootConfig,
    selectedRange,
    selectedView,
    selectedLayer,
    selectedVariant,
    selectedOptions,
    selectedCollection,
    confirmedLayers
  } = state;

  if (!rootConfig) {
    return null;
  }

  const rangesByCategory = groupBy(
    rootConfig.ranges.filter(
      (range) => range.collection_id === selectedCollection.id
    ),
    (range) => range.category
  );

  // We preserve hood setting between layer change, but we only show it when possible
  const hoodLayer = selectedVariant?.layers.find((l) => l.target === "hood");
  const showHood = !!hoodLayer && selectedOptions[hoodLayer.id].name !== "Off";

  const navigateTo = (path) => {
    const query = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    navigate({
      pathname: path,
      search: createSearchParams(query).toString()
    });
  };

  return (
    <div className={styles.container}>
      <div
        className={cx(styles.previewContent, showHood && styles.withHood)}
        style={{
          backgroundImage:
            selectedVariant?.background_image &&
            `url(${selectedVariant.background_image})`
        }}
      >
        {showHood && (
          <LayeredCanvas
            imageSize={rootConfig.imageSize}
            pixelMargin={selectedVariant.hood_margin || selectedVariant.margin}
            layers={[
              {
                url: evaluate(
                  hoodLayer.image_template ||
                  selectedOptions[hoodLayer.id].image,
                  {
                    range: selectedRange,
                    view: selectedView,
                    variant: selectedVariant,
                    options: selectedOptions,
                    layer: hoodLayer
                  }
                )
              }
            ]}
            markers={[]}
            onMarkerClick={() => {
            }}
          />
        )}

        {selectedVariant && (
          <LayeredCanvas
            imageSize={rootConfig.imageSize}
            pixelMargin={selectedVariant.margin}
            layers={selectedVariant.layers
              .filter((layer) => {
                if (layer.target) {
                  return false;
                }
                if (!layer.render_condition) {
                  return true;
                }
                return (
                  evaluate(layer.render_condition, {
                    range: selectedRange,
                    view: selectedView,
                    variant: selectedVariant,
                    layer,
                    option: selectedOptions[layer.id],
                    options: selectedOptions
                  }) === "true"
                );
              })
              .map((layer) => ({
                url: evaluate(
                  layer.image_template || selectedOptions[layer.id].image,
                  {
                    range: selectedRange,
                    view: selectedView,
                    variant: selectedVariant,
                    layer,
                    option: selectedOptions[layer.id],
                    options: selectedOptions
                  }
                )
              }))}
            markers={(showMarkers ? selectedVariant.markers : [])
              .map((marker) => {
                  const layer = selectedVariant.layers.find(
                    (layer) => layer.id === marker.layer_id
                  );

                  return {
                    layer_id: marker.layer_id,
                    selected: selectedLayer?.id === marker.layer_id,
                    confirmed: confirmedLayers.includes(marker.layer_id),
                    coords: marker.position,
                    label: gettext(marker.name),
                    warning: layer.options.some(
                      (option) =>
                        option.disable_condition &&
                        evaluate(option.disable_condition, {
                          range: selectedRange,
                          view: selectedView,
                          variant: selectedVariant,
                          options: selectedOptions,
                          layer,
                          option
                        }) === "true"
                    )
                  };
                }
              )}
            onMarkerClick={(marker) => {
              const layer = selectedVariant.layers.find(
                (layer) => layer.id === marker.layer_id
              );
              actions.selectLayer(layer);
            }}
          />
        )}

        <div className={styles.previewControls}>
          <SquareCheckbox
            className={styles.checkbox}
            label={gettext("Hide markers")}
            selected={!showMarkers}
            onChange={() => {
              setShowMarkers(!showMarkers);
            }}
          />

          <div className={styles.viewToggle}>
            {state.selectedRange.views.map((view) => (
              <Tooltip key={view.variant_id} label={gettext(view.name)}>
                <RoundButton
                  imageSrc={view.preview_icon}
                  selected={selectedView === view}
                  onClick={() => {
                    actions.selectView(view);
                  }}
                />
              </Tooltip>
            ))}
          </div>

          {/* That's download and print icons placeholder */}
          <Footnote />
        </div>
      </div>

      <div className={styles.pickerContent}>
        <div className={styles.picker}>
          <ElementList
            label={gettext("Range")}
            selectionLabel={`${selectedRange.category} · ${gettext(selectedRange.name)}`}
            expandable
            addon={
              <div className={styles.collectionList}>
                {rootConfig.collections.map((collection, index) => (
                  <Fragment key={index}>
                    <Link
                      selected={selectedCollection.id === collection.id}
                      onClick={(event) => {
                        actions.selectCollection(collection);
                        event.stopPropagation();
                      }}
                    >
                      {gettext(collection.name)}
                    </Link>
                    {index !== rootConfig.collections.length - 1 && " · "}
                  </Fragment>
                ))}
              </div>
            }
          >
            <SectionList>
              {Object.entries(rangesByCategory).map(([category, ranges]) => (
                <Fragment key={category}>
                  <SectionList.Section label={category} />
                  {ranges.map((range, index) => (
                    <ImageElementButton
                      key={index}
                      src={range.preview_icon}
                      selected={selectedRange.id === range.id}
                      wide
                      label={gettext(range.name)}
                      onClick={() => {
                        actions.selectRange(range);
                      }}
                    />
                  ))}
                </Fragment>
              ))}
            </SectionList>
          </ElementList>
          {(selectedVariant?.layers || [])
            .filter((layer) => {
              if (!layer.options) {
                return false;
              }
              if (!layer.render_condition) {
                return true;
              }
              return (
                evaluate(layer.render_condition, {
                  range: selectedRange,
                  view: selectedView,
                  variant: selectedVariant,
                  layer,
                  option: selectedOptions[layer.id],
                  options: selectedOptions
                }) === "true"
              );
            })
            .map((layer) => (
              <ElementList
                key={layer.id}
                label={
                  layer.target === "hood" ? (
                    <>
                      {gettext(layer.name)}{" "}
                      <Tooltip
                        size="large"
                        label={
                          <div className={styles.hoodTooltip}>
                            <img src={HoodPreview} alt="" />
                            <div>
                              {gettext(
                                "The hood is 8\" wider than the chosen range and matches its color"
                              )}
                            </div>
                          </div>
                        }
                      >
                        <img src={InfoIcon} alt="" />
                      </Tooltip>
                    </>
                  ) : (
                    gettext(layer.name)
                  )
                }
                selected={selectedLayer?.id === layer.id}
                selectionLabel={
                  layer.target === "hood" &&
                  selectedOptions[layer.id].name === "Off"
                    ? ""
                    : confirmedLayers.includes(layer.id)
                      ? gettext(selectedOptions[layer.id].name)
                      : null
                }
                hideSelection={
                  layer.target === "hood" &&
                  selectedOptions[layer.id].name === "Off"
                }
                expandable={
                  layer.target !== "hood" ||
                  selectedOptions[layer.id].name !== "Off"
                }
                addon={
                  layer.target === "hood" && (
                    <SquareCheckbox
                      className={styles.hoodToggle}
                      checked={selectedOptions[layer.id].name !== "Off"}
                      onChange={(event) => {
                        const checked = event.target.checked;
                        actions.selectOption(
                          layer,
                          checked ? layer.options[1] : layer.options[0]
                        );
                        if (checked) {
                          actions.selectLayer(layer);
                        }
                      }}
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    />
                  )
                }
                warning={layer.options.some(
                  (option) =>
                    option.disable_condition &&
                    evaluate(option.disable_condition, {
                      range: selectedRange,
                      view: selectedView,
                      variant: selectedVariant,
                      options: selectedOptions,
                      layer,
                      option
                    }) === "true"
                )}
                onExpanded={(expanded) => {
                  if (!expanded && selectedLayer === layer) {
                    actions.selectLayer(null);
                  }
                  if (expanded) {
                    actions.selectLayer(layer);
                  }
                }}
              >
                <SectionList>
                  {layer.options
                    .filter((option) => !option.hidden)
                    .map((option, index) => {
                      const selected =
                        selectedOptions[layer.id]?.id === option.id;

                      const disabled =
                        option.disable_condition &&
                        evaluate(option.disable_condition, {
                          range: selectedRange,
                          view: selectedView,
                          variant: selectedVariant,
                          options: selectedOptions,
                          layer,
                          option
                        }) === "true";

                      const onClick = () => {
                        actions.selectOption(layer, option);
                        actions.selectLayer(layer);
                      };

                      if (!option.preview) {
                        return (
                          <TextElementButton
                            key={index}
                            disabled={disabled}
                            selected={selected}
                            label={gettext(option.name)}
                            onClick={onClick}
                          />
                        );
                      }

                      if (option.preview.color) {
                        return (
                          <ColorElementButton
                            key={index}
                            disabled={disabled}
                            color={option.preview.color}
                            selected={selected}
                            label={gettext(option.name)}
                            narrow={layer.option_render_style === "narrow"}
                            onClick={onClick}
                          />
                        );
                      }

                      if (option.preview.image) {
                        return (
                          <ImageElementButton
                            key={index}
                            disabled={disabled}
                            src={option.preview.image}
                            selected={selected}
                            label={gettext(option.name)}
                            narrow={layer.option_render_style === "narrow"}
                            onClick={onClick}
                          />
                        );
                      }

                      console.warn(
                        "Unsupported preview item",
                        option,
                        option.preview
                      );
                      return null;
                    })}
                </SectionList>
              </ElementList>
            ))}
          <div id={"stretchElement"} />
        </div>
        <div className={styles.actions}>
          <div className={styles.actionsRow}>
            <Button
              className={styles.actionButton}
              onClick={() => navigateTo("summary")}
            >
              {gettext("Range summary")}
            </Button>
            <Button
              className={styles.actionButton}
              filled
              onClick={() => navigateTo("quote")}
            >
              {gettext("Get a quote")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RangeView;
