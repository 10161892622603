import { useState } from "react";
import { useLocales } from "react-localized";
import AnimateHeight from "react-animate-height";
import cx from "classnames";

import Link from "components/Link";

import Logo from "assets/logo.png";
import ExpandIcon from "assets/expand.svg";
import CollapseIcon from "assets/collapse.svg";

import styles from "./PageWrapper.module.css";
import Dropdown from "./Dropdown";
import { Button } from "@mui/material";
import useOptions from "../utils/options";

function PageWrapper({ store, children }) {
  const { gettext } = useLocales();
  const [promoCollapsed, setPromoCollapsed] = useState(false);
  const { languageOptions } = useOptions();
  const { state, actions } = store;

  if (!state.language) {
    return null;
  }

  return (
    <main className={cx(styles.container, styles.screenOnly)}>
      <div className={styles.header}>
        <Button
          size={"small"}
          className={styles.closeButton}
          onClick={() => {
            window.open("https://www.french-barn.com", "_self");
          }}
        >
          {/*<img src={CloseIcon} alt={gettext('Close')} />*/}
          {gettext("Close the configurator")}
        </Button>

        <Dropdown
          className={styles.language}
          options={languageOptions}
          name="language"
          value={state.language}
          onChange={({ selectedItem }) => actions.selectLanguage(selectedItem?.id)
          }
        />
      </div>

      <img className={styles.logo} alt={gettext("Lacanche logo")} src={Logo} />
      <h1 className={styles.title}>
        {gettext("Customize your Lacanche Range!")}
      </h1>
      <div
        className={cx(
          styles.collapsibleBox,
          promoCollapsed && styles.collapsed
        )}
      >
        <button
          className={styles.collapseButton}
          type="button"
          onClick={() => setPromoCollapsed((v) => !v)}
        >
          <img
            src={promoCollapsed ? ExpandIcon : CollapseIcon}
            alt={promoCollapsed ? gettext("Expand") : gettext("Collapse")}
          />
        </button>
        <div className={styles.collapseContent}>
          <AnimateHeight duration={500} height={promoCollapsed ? 0 : "auto"}>
            <p className={styles.promo}>
              {gettext(
                "Our configurator tool lets you design your very own Lacanche range."
              )}
            </p>
            <ul className={cx(styles.promoList)}>
              <li>
                {gettext(
                  "Start by selecting your favorite model, then make it your own with one of our 30 colors, 5 finishes and a wide selection of top and oven configurations."
                )}
              </li>
              <li>
                {gettext(
                  "Each feature can be accessed through the interactive dots on the range or dropdown menus."
                )}
              </li>
              <li>
                {gettext(
                  "Use the \"Range Summary\" to review and share your configuration, or the \"Get a Quote\" page to receive a detailed estimate directly to your inbox."
                )}
              </li>
            </ul>
            <p className={styles.promo}>
              {gettext(
                "Please feel free to reach us directly if you have any questions, "
              )}
              <a
                className={styles.promoLink}
                href="mailto:sales@french-barn.com"
              >
                {gettext("sales@french-barn.com")}
              </a>
              {gettext(" or 855-252-0505")}
            </p>
          </AnimateHeight>
          <Link
            className={styles.expandLink}
            onClick={() => setPromoCollapsed(false)}
          >
            {gettext("Show description")}
          </Link>
        </div>
      </div>
      <div className={styles.content}>{children}</div>
    </main>
  );
}

export default PageWrapper;
