import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';

const UnauthorizedPage = () => {
  return (
    <Container fixed>
      <Box sx={{ height: '100vh' }}>
        <Grid container spacing={2} justifyContent={'center'} alignItems={'center'} height={'100%'}>
          <Grid item xs={12}>
            <Typography align={'center'} variant={'h4'}>
              Oops!
            </Typography>
            <Typography align={'center'} variant={'h5'}>
              You don't have permission to view this page.
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default UnauthorizedPage;
