import cx from 'classnames';

import styles from './SquareCheckbox.module.css';

function SquareCheckbox({ className, label, ...otherProps }) {
  const inputId = otherProps.id || `chk-${Math.random()}`;

  return (
    <div className={cx(className, styles.container)}>
      <input
        className={styles.input}
        id={inputId}
        type="checkbox"
        {...otherProps}
      />
      <label className={styles.label} htmlFor={inputId}>
        {label}
      </label>
    </div>
  );
}

export default SquareCheckbox;
