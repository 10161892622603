import cx from 'classnames';

import styles from './Input.module.css';

function Input({ className, type, label, required, ...otherProps }) {
  return (
    <label className={cx(className, styles.label)}>
      {label}
      {required && ' *'}
      {type === 'textarea' && (
        <textarea
          className={cx(styles.input, styles.textArea)}
          required={required}
          {...otherProps}
        />
      )}
      {type !== 'textarea' && (
        <input
          className={cx(styles.input)}
          type={type}
          required={required}
          {...otherProps}
        />
      )}
    </label>
  );
}

export default Input;
