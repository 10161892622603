import { Fragment, useEffect, useState } from 'react';
import { useLocales } from 'react-localized';
import { format } from 'date-fns';
import { enGB, fr } from 'date-fns/locale';
import cx from 'classnames';

import Button from 'components/Button';
import RangePreview from 'components/RangePreview';

import useAppState from 'utils/use-app-state';
import { convertRangeState, fetchSummary } from 'utils/api';
import { formatNumber } from 'utils/formatters';

import CloseIcon from 'assets/close-icon.svg';

import styles from './RangeSummaryPage.module.css';
import Footnote from '../components/Footnote';
import Logo from '../assets/logo.png';
import SecondaryLogo from '../assets/secondary-logo.png';
import { createSearchParams, useNavigate } from 'react-router-dom';
import qs from 'qs';

const DateLocales = { en: enGB, fr };

function RangeSummaryPage({ onNavigate }) {
  const { gettext, locale } = useLocales();
  const navigate = useNavigate();

  const [state] = useAppState(false);
  const [requirementsInfo, setRequirementsInfo] = useState(null);

  useEffect(() => {
    if (!state.loaded) {
      return;
    }
    const process = async () => {
      try {
        const requirements = await fetchSummary(convertRangeState(state));
        setRequirementsInfo(requirements);
      } catch (error) {
        console.log('Failed to fetch price', error);
      }
    };
    process();
  }, [state]);

  if (!state.loaded) {
    return null;
  }

  const collection = state.rootConfig.collections.find(
    (collection) => collection.id === state.selectedRange.collection_id
  );

  const navigateTo = (path) => {
    const query = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    navigate({
      pathname: path,
      search: createSearchParams(query).toString()
    });
  };

  return (
    <main className={styles.container}>
      <h1 className={cx(styles.title, styles.screenOnly)}>
        <button
          className={styles.closeButton}
          onClick={() => {
            navigateTo('/');
          }}
        >
          <img src={CloseIcon} alt={gettext('Close')} />
        </button>
        {gettext('Presenting your Lacanche Range')}
      </h1>

      <div className={styles.header}>
        <div className={styles.headerSide}>
          <div className={styles.headerTitle}>
            {gettext(state.selectedRange.name)} {gettext(collection.name)}{' '}
            {gettext(state.selectedRange.category)}
          </div>
        </div>

        <Button
          className={cx(styles.headerButton, styles.screenOnly)}
          filled
          onClick={() => {
            window.print();
          }}
        >
          {gettext('Print')}
        </Button>
        <div className={cx(styles.dateLabel, styles.printOnly)}>
          {format(new Date(), 'PPP', { locale: DateLocales[locale] })}
        </div>
      </div>

      <div className={styles.content}>
        <div className={cx(styles.contentColumn, styles.screenOnly)}>
          <RangePreview
            rootConfig={state.rootConfig}
            rangeConfig={state.rangeConfig}
            range={state.selectedRange}
            options={state.selectedOptions}
            view={state.selectedRange.views[0]}
          />
        </div>

        <div className={cx(styles.contentColumn, styles.preview, styles.printOnly)}>
          {state.selectedRange.views.map((view, index) => (
            <div className={styles.contentRow} key={index}>
              <RangePreview
                className={styles.previewSmall}
                rootConfig={state.rootConfig}
                rangeConfig={state.rangeConfig}
                range={state.selectedRange}
                options={state.selectedOptions}
                view={view}
              />
            </div>
          ))}
        </div>

        <div className={styles.contentColumn}>
          <div className={cx(styles.contentRow, styles.screenOnly)}>
            {state.selectedRange.views.slice(1).map((view, index) => (
              <div className={styles.contentColumn} key={index}>
                <RangePreview
                  className={styles.previewSmall}
                  rootConfig={state.rootConfig}
                  rangeConfig={state.rangeConfig}
                  range={state.selectedRange}
                  options={state.selectedOptions}
                  view={view}
                />
              </div>
            ))}
          </div>

          <div className={styles.optionList}>
            {Object.entries(state.selectedOptions).map(
              ([layerId, option], index) => {
                const layer = state.selectedVariant.layers.find(
                  (layer) => layer.id === layerId
                );

                // TODO: Search for this layer across all variants
                if (option.missing || layer.target === 'hood') {
                  return null;
                }

                return (
                  <Fragment key={index}>
                    <div className={styles.optionTitle}>
                      {gettext(layer.name)}
                    </div>
                    <div className={styles.optionValue}>
                      {gettext(option.name)}
                    </div>
                  </Fragment>
                );
              }
            )}

            <div className={styles.optionTitle}>
              {gettext('Total Output')}
            </div>
            <div className={styles.optionValue}>
              {formatNumber(requirementsInfo?.total_btu || 0)}
              {gettext('BTU')}
            </div>
            <div className={styles.optionTitle}>
              {gettext('Electrical')}
            </div>
            <div className={styles.optionValue}>
              {requirementsInfo?.total_amps || 0}
              {gettext('Amps')}
            </div>
            <hr className={styles.priceSeparator} />
            <div className={styles.priceContainer}>
              <Footnote />
            </div>
          </div>
        </div>
      </div>

      <div className={cx(styles.footer, styles.printOnly)}>
        <img
          className={styles.logo}
          alt={gettext('Secondary logo')}
          src={SecondaryLogo} />

        <div className={styles.headerOptions}>
          {gettext('Lacanche - Premium range cookers from France')}
        </div>

        <img
          className={styles.logo}
          alt={gettext('Lacanche logo')}
          src={Logo} />
      </div>
    </main>
  );
}

export default RangeSummaryPage;
